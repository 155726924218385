// src/components/features/DataAnalyst/PredefinedDatasets.js
import React from 'react';
import { List, ListItem, ListItemText, ListItemButton } from '@mui/material';

const predefinedDatasets = [
  { name: 'Cars Dataset', file: 'cars.json' },
  { name: 'Wheat Dataset', file: 'wheat.json' },
  // Add more predefined datasets here
];

function PredefinedDatasets({ onSelect }) {
  return (
    <List>
      {predefinedDatasets.map((dataset, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton onClick={() => onSelect(dataset.file)}>
            <ListItemText primary={dataset.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default PredefinedDatasets;
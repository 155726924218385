// src/components/features/AIQuest/IdeaGeneration/ExportToPDF.js

import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { usePDF } from 'react-to-pdf';
import { renderPainPoints, renderRootCauseAnalysis, renderIdeas, renderSolutionMapping, renderFeasibilityAssessment, renderImpactScoring, renderPrioritizationMatrix, renderActionPlanning } from './renderFunctions';
import BusinessContext from './BusinessContext';

const ExportToPDF = ({ generatedIdeas, businessContext, painPoints, mcqAnswers, mcqQuestions }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { toPDF, targetRef } = usePDF({
    filename: 'WISSP_AI_Report.pdf',
    options: {
      unit: 'mm',
      format: [210, 297], // A4 size
      hotfixes: ["px_scaling"],
      font: 'helvetica',
    },
  });

  const renderPage = (content, pageNumber) => (
    <Box
      sx={{
        width: '210mm',
        height: '297mm',
        pageBreakAfter: 'always',
        position: 'relative',
        padding: '15mm 10mm', // Reduced side margins
        boxSizing: 'border-box',
        backgroundColor: 'white',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ height: '267mm', overflowY: 'hidden' }}>
        {content}
      </Box>
      <Typography
        sx={{
          position: 'absolute',
          bottom: '10mm',
          right: '10mm',
          fontSize: '10pt',
        }}
      >
        Page {pageNumber}
      </Typography>
      {pageNumber > 2 && (
        <Typography
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(-45deg)',
            fontSize: '48pt',
            color: 'lightgrey',
            opacity: 0.2,
            pointerEvents: 'none',
          }}
        >
          WISSP.AI
        </Typography>
      )}
    </Box>
  );

  const renderSection = (title, content, pageNumber) => (
    <>
      {renderPage(
        <Box>
          <Typography variant="h4" gutterBottom>{title}</Typography>
          {content}
        </Box>,
        pageNumber
      )}
      {renderPage(<Box>{content}</Box>, pageNumber + 1)}
    </>
  );

  const handleExport = async () => {
    setIsExporting(true);
    await new Promise(resolve => setTimeout(resolve, 100));
    await toPDF();
    setIsExporting(false);
  };

  return (
    <>
      <Button onClick={handleExport} variant="contained" color="primary">
        Export to PDF
      </Button>
      <Box ref={targetRef} sx={{ display: isExporting ? 'block' : 'none' }}>
        {renderPage(
          <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: 'primary.main' }}>WISSP.AI</Typography>
          </Box>,
          1
        )}
        {renderPage(
          <BusinessContext businessContext={businessContext} painPoints={painPoints} mcqAnswers={mcqAnswers} mcqQuestions={mcqQuestions} />,
          2
        )}
        {renderSection('Pain Points', renderPainPoints(generatedIdeas.pain_point_identification|| {}), 3)}
        {renderSection('Root Cause Analysis', renderRootCauseAnalysis(generatedIdeas.root_cause_analysis|| {}), 5)}
        {renderSection('Generated Ideas', renderIdeas(generatedIdeas?.idea_generation || {}), 7)}
        {renderSection('Solution Mapping', renderSolutionMapping(generatedIdeas.solution_mapping|| {}), 9)}
        {renderSection('Feasibility Assessment', renderFeasibilityAssessment(generatedIdeas.feasibility_assessment|| {}), 11)}
        {renderSection('Impact Scoring', renderImpactScoring(generatedIdeas.impact_scoring|| {}), 13)}
        {renderPage(
          <Box>
            <Typography variant="h4" gutterBottom>Prioritization Matrix</Typography>
            {renderPrioritizationMatrix(generatedIdeas.prioritization_matrix|| {})}
          </Box>,
          15
        )}
        {renderSection('Action Planning', renderActionPlanning(generatedIdeas.action_planning|| {}), 16)}
      </Box>
    </>
  );
};

export default ExportToPDF;
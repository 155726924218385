// src/data/aiMaturityMetadata.js

export const aiMaturityMetadata = {
    Absent: {
      description: "A company at this stage is either oblivious to Big Data and AI or believes it does not need it. User-centered thinking is not at all part of how it works. (User-centered is a mindset supported by activities that keep the users as the driver behind strategy, tactics, and decisions.)",
      characteristics: [
        "AI initiatives are ignored or nonexistent",
        "Oblivious or believes AI doesn't apply",
        "User-centered thinking is not part of the workflow"
      ],
      obstacles: [
        "Lack of education about AI, its benefits, and possible internal AI processes",
        "No recognition of AI as a valuable discipline",
        "No AI-dedicated roles, processes, or budget"
      ],
      resources: [
        { title: "AI Basics", url: "https://www.example.com/ai-basics" },
        { title: "Benefits of AI in Business", url: "https://www.example.com/ai-benefits" },
        { title: "Introduction to User-Centered Design", url: "https://www.example.com/user-centered-design" },
        { title: "AI Use Cases Across Industries", url: "https://www.example.com/ai-use-cases" }
      ]
    },
    Limited: {
      description: "An organization in the limited stage approaches Big Data and AI erratically. Small Big Data and AI efforts are made, usually for one of three reasons: legal necessity, a Big Data and AI-aware individual who takes initiative, or an experimental team that attempts Big Data and AI methods.",
      characteristics: [
        "Uneven or haphazard AI approaches",
        "Occasional AI activities, not routinely done",
        "AI falls low among priorities"
      ],
      obstacles: [
        "Learning methods for doing AI work",
        "Organizing a team of multiple people",
        "Establishing routines and resources",
        "Inconsistent execution and incorporation into strategy"
      ],
      resources: [
        { title: "AI Project Management Basics", url: "https://www.example.com/ai-project-management" },
        { title: "Building Your First AI Team", url: "https://www.example.com/building-ai-team" },
        { title: "AI Strategy for Beginners", url: "https://www.example.com/ai-strategy-basics" },
        { title: "Demonstrating AI Value to Stakeholders", url: "https://www.example.com/ai-value-demonstration" }
      ]
    },
    Emergent: {
      description: "When Big Data and AI maturity is emergent, organizations exhibit Big Data and AI work in more teams, engage in some Big Data and AI-related planning, and may have Big Data and AI budgets. The Big Data and AI efforts are, however, small, unstable, and based on individual manager initiatives rather than organizational policies.",
      characteristics: [
        "Functional but promising AI initiatives",
        "Inconsistent and inefficient application",
        "People in AI roles, but not necessarily with the right skills or numbers"
      ],
      obstacles: [
        "Proving the value and impact of AI",
        "Lack of widespread, systematic AI processes",
        "AI not yet prioritized as an essential strategy",
        "Misallocation of resources in AI-related work"
      ],
      resources: [
        { title: "Developing an AI Culture", url: "https://www.example.com/ai-culture-development" },
        { title: "AI Strategy Roadmap", url: "https://www.example.com/ai-strategy-roadmap" },
        { title: "Scaling AI Initiatives", url: "https://www.example.com/scaling-ai-initiatives" },
        { title: "AI Skills Assessment and Development", url: "https://www.example.com/ai-skills-assessment" }
      ]
    },
    Structured: {
      description: "Structured Big Data and AI means that the organization recognizes the value of Big Data and AI and has established a full Big Data and AI team or multiple teams. Leadership usually supports Big Data and AI and sometimes incorporates it into high-level strategies. There is a centralized definition of design and a shared, iterative human-centered design process.",
      characteristics: [
        "Effective AI initiatives with semi-systematic methodologies",
        "Full AI teams established",
        "Leadership support for AI",
        "AI incorporated into high-level strategies"
      ],
      obstacles: [
        "Hidden weaknesses in AI strategy",
        "Tensions with responsibilities and ownership as teams scale",
        "Success metrics not aligned with AI goals",
        "Development processes that don't include discovery research or iterative design"
      ],
      resources: [
        { title: "Advanced AI Methodologies", url: "https://www.example.com/advanced-ai-methodologies" },
        { title: "AI Leadership and Management", url: "https://www.example.com/ai-leadership" },
        { title: "Aligning AI with Business Objectives", url: "https://www.example.com/ai-business-alignment" },
        { title: "AI-Driven Discovery and Design Processes", url: "https://www.example.com/ai-driven-design" }
      ]
    },
    Integrated: {
      description: "Organizations at this stage have successful and sustainable Big Data and AI practices. Most teams are performing efficiently and effectively and have a strong focus on users. There is often innovation in Big Data and AI methods and processes and, sometimes, there is even contribution to Big Data and AI as a field.",
      characteristics: [
        "Pervasive and universally applied AI across the organization",
        "High awareness and buy-in for AI-focused activities",
        "Strong focus on users and meeting their needs",
        "Innovation in AI methods and processes"
      ],
      obstacles: [
        "Maintaining high level of AI integration",
        "Continuing to innovate in AI methods and processes",
        "Balancing between maintaining current success and pushing boundaries",
        "Contributing to the AI field while focusing on organizational goals"
      ],
      resources: [
        { title: "AI Innovation Techniques", url: "https://www.example.com/ai-innovation" },
        { title: "AI Ethics and Governance", url: "https://www.example.com/ai-ethics" },
        { title: "Contributing to the AI Field", url: "https://www.example.com/contributing-ai-field" },
        { title: "Next-Generation AI Technologies", url: "https://www.example.com/next-gen-ai" }
      ]
    }
  };
// src/components/features/AIQuest/InsightCard.js
import React from 'react';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';

function InsightCard({ title, value, maxValue, description }) {
  const percentage = (value / maxValue) * 100;

  return (
    <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Box sx={{ position: 'relative', display: 'inline-flex', my: 2 }}>
        <CircularProgress variant="determinate" value={percentage} size={80} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" component="div" color="text.secondary">
            {value.toFixed(1)}
          </Typography>
        </Box>
      </Box>
      {description && <Typography variant="body2">{description}</Typography>}
    </Paper>
  );
}

export default InsightCard;
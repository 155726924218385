// src/components/features/DataAnalyst/DataSummarySection.js
import React, { useState } from 'react';
import { Box, Typography, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppContext } from '../../../contexts/AppContext';

function DataSummarySection({ summary, onGenerateSummary, onNext }) {
  const [explorationResults, setExplorationResults] = useState(null);
  const { exploreData } = useAppContext();

  const handleExplore = async () => {
    const results = await exploreData();
    setExplorationResults(results);
  };

  return (
    <Box>
      <Typography variant="h6">Data Summary & Exploration</Typography>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Data Summarization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {summary ? (
            <Typography>{summary}</Typography>
          ) : (
            <Button onClick={onGenerateSummary}>Generate Summary</Button>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Automated Data Exploration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!explorationResults ? (
            <Button onClick={handleExplore}>Explore Data</Button>
          ) : (
            explorationResults.map((result, index) => (
              <Typography key={index}>{result.goal}: {result.insight}</Typography>
            ))
          )}
        </AccordionDetails>
      </Accordion>

      <Button onClick={onNext} disabled={!summary || !explorationResults}>Next</Button>
    </Box>
  );
}

export default DataSummarySection;


// src/components/App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Layout from './layout/Layout';
import Login from './Login';
import Register from './Register';
import theme from '../styles/theme';
import AppProvider from '../contexts/AppContext';
import { AuthProvider, useAuth } from '../contexts/AuthContext';
import AIQuestSurvey from './AIQuestSurvey';
import AIQuestInstructions from './features/AIQuest/AIQuestInstructions';
import AIQuestDashboard from './features/AIQuest/AIQuestDashboard';
import AIQuestInsights from './features/AIQuest/AIQuestInsights';
import AIQuestLanding from './features/AIQuest/AIQuestLanding';
import AIQuestSavedResponses from './features/AIQuest/AIQuestSavedResponses';
import {useLocation } from 'react-router-dom';
import DataAnalystPage from './features/DataAnalyst/DataAnalystPage';
import CompanyInsights from './features/AIQuest/CompanyInsights';
// import IdeaGenerationDashboard from './features/AIQuest/IdeaGenerationDashboard';
import IdeaGenerationDashboard from './features/AIQuest/IdeaGeneration/IdeaGenerationDashboard';

const queryClient = new QueryClient();

function ProtectedRoute({ children }) {
  const { user, token } = useAuth();
  const location = useLocation();
  
  if (!user && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route 
                path="/*" 
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                } 
              >
                {/* Keep the original routes */}
                <Route path="aiquest" element={<AIQuestLanding />} />
                <Route path="aiquest/survey" element={<AIQuestSurvey />} />
                <Route path="aiquest/instructions" element={<AIQuestInstructions />} />
                <Route path="aiquest/dashboard" element={<AIQuestDashboard />} />
                <Route path="aiquest/insights" element={<AIQuestInsights />} />
                <Route path="aiquest/saved-responses" element={<AIQuestSavedResponses />} />
                <Route path="aiquest/company-insights" element={<CompanyInsights />} />
                <Route path="aiquest/idea-generation" element={<IdeaGenerationDashboard />} />
                {/* <Route path="data-analyst" element={<DataAnalystPage />} /> */}
              </Route>
            </Routes>
          </ThemeProvider>
        </AppProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
// src/components/features/AIQuest/IdeaGeneration/SectionRenderer.js
import React from 'react';
import { Typography, Box } from '@mui/material';
import {
  renderPainPoints,
  renderRootCauseAnalysis,
  renderIdeas,
  renderSolutionMapping,
  renderFeasibilityAssessment,
  renderImpactScoring,
  renderPrioritizationMatrix,
  renderActionPlanning
} from './renderFunctions';

function SectionRenderer({ sectionKey, title, color, data, organization, interviewee }) {
  console.log(`Rendering section: ${sectionKey}`, data);

  const renderSectionContent = () => {
    switch (sectionKey) {
      case 'pain_point_identification':
        return renderPainPoints(data, organization, interviewee);
      case 'root_cause_analysis':
        return renderRootCauseAnalysis(data, organization, interviewee);
      case 'idea_generation':
        return renderIdeas(data, organization, interviewee);
      case 'solution_mapping':
        return renderSolutionMapping(data, organization, interviewee);
      case 'feasibility_assessment':
        return renderFeasibilityAssessment(data, organization, interviewee);
      case 'impact_scoring':
        return renderImpactScoring(data, organization, interviewee);
      case 'prioritization_matrix':
        return renderPrioritizationMatrix(data.prioritization_matrix, organization, interviewee);
      case 'action_planning':
        return renderActionPlanning(data, organization, interviewee);
      default:
        return <Typography>Unknown section: {sectionKey}</Typography>;
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom color={color}>
        {title}
      </Typography>
      {renderSectionContent()}
    </Box>
  );
}

export default SectionRenderer;

// checkpoint
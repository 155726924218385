// src/components/features/AIQuest/AIQuestDashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, Tabs, Tab, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Button, ToggleButton, ToggleButtonGroup, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { aiquestQuestions } from '../../../data/aiquest_questions';
import { aiMaturityMetadata } from '../../../data/aiMaturityMetadata';
import { useCasesData } from '../../../data/useCasesData';
import { teamData } from '../../../data/teamData';
import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../services/api';
import AIReadinessStages from './AIReadinessStages';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssessmentIcon from '@mui/icons-material/Assessment';
import OrgUserSelector from './OrgUserSelector';
import { getAIQuestInsights, getAIQuestDashboardData, getUserOrganizationsAndInterviewees } from '../../../services/api';

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #C8326B 30%, #D3437B 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(200, 50, 107, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  '&:hover': {
    background: 'linear-gradient(45deg, #B32D60 30%, #BF3C6F 90%)',
  },
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  ...(selected && {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(145deg, #ffffff 0%, #f4f4f4 100%)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(145deg, #ffffff 0%, #f0f0f0 100%)',
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.05)',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const maturityStages = [
  { name: 'Absent', minScore: 0, maxScore: 30, color: '#FF6B6B' },
  { name: 'Limited', minScore: 31, maxScore: 50, color: '#FFA06B' },
  { name: 'Emergent', minScore: 51, maxScore: 70, color: '#FFD56B' },
  { name: 'Structured', minScore: 71, maxScore: 85, color: '#B2FF6B' },
  { name: 'Integrated', minScore: 86, maxScore: 100, color: '#6BFF8A' },
];


function AIQuestDashboard() {
  const { selectedOrganization, selectedUser } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeExampleTab, setActiveExampleTab] = useState('Data Discovery');
  const [viewMode, setViewMode] = useState('By Use Cases');
  const [selectedResource, setSelectedResource] = useState('Overview');
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOrganization && selectedUser) {
      fetchDashboardData();
    } else {
      setLoading(false);
    }
  }, [selectedOrganization, selectedUser]);

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getAIQuestDashboardData(selectedOrganization, selectedUser);
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleResourceSelect = (resource) => {
    setSelectedResource(resource);
  };

  const handleViewInsights = () => {
    navigate('/aiquest/insights');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleExampleTabChange = (event, newValue) => {
    setActiveExampleTab(newValue);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
      setActiveExampleTab(viewMode === 'By Use Cases' ? 'Sales' : 'Data Discovery');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography color="error">{error}</Typography>
        <OrgUserSelector />
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>Select an organization and user to view the dashboard.</Typography>
        <OrgUserSelector />
      </Box>
    );
  }

  const { scores, maturityStage } = dashboardData;
  const stageMetadata = aiMaturityMetadata[maturityStage];


  return (
    <Box sx={{ p: 4, bgcolor: '#f7f7f7' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
        AI Readiness Dashboard
      </Typography>
      
      <OrgUserSelector />

      <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#34495e' }}>
          Your AI Maturity Stage: <span style={{ color: '#e74c3c' }}>{maturityStage}</span>
        </Typography>
        <AIReadinessStages currentStage={maturityStages.findIndex(stage => stage.name === maturityStage)} />
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
          Resources to Get Started
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <List component="nav" aria-label="resource categories">
              {['Overview', 'Characteristics', 'Obstacles', 'Resources'].map((text) => (
                <StyledListItem
                  button
                  key={text}
                  selected={selectedResource === text}
                  onClick={() => handleResourceSelect(text)}
                >
                  <ListItemText primary={text} />
                </StyledListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ color: '#C8326B' }}>
                  {selectedResource} - {maturityStage} Stage
                </Typography>
                {selectedResource === 'Overview' && (
                  <Typography variant="body1">{stageMetadata.description}</Typography>
                )}
                {selectedResource === 'Characteristics' && (
                  <List>
                    {stageMetadata.characteristics.map((char, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon sx={{ color: '#27ae60' }} />
                        </ListItemIcon>
                        <ListItemText primary={char} />
                      </ListItem>
                    ))}
                  </List>
                )}
                {selectedResource === 'Obstacles' && (
                  <List>
                    {stageMetadata.obstacles.map((obstacle, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <ErrorOutlineIcon sx={{ color: '#e74c3c' }} />
                        </ListItemIcon>
                        <ListItemText primary={obstacle} />
                      </ListItem>
                    ))}
                  </List>
                )}
                {selectedResource === 'Resources' && (
                  <Grid container spacing={2}>
                    {stageMetadata.resources.map((resource, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          startIcon={<LibraryBooksIcon />}
                          href={resource.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ justifyContent: 'flex-start', textAlign: 'left' }}
                        >
                          {resource.title}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
          AI Implementation WISSPs
        </Typography>
        <StyledToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleViewModeChange}
          aria-label="view mode"
          sx={{ mb: 2 }}
        >
          <ToggleButton value="By Team" aria-label="by team">
            By Team
          </ToggleButton>
          <ToggleButton value="By Use Cases" aria-label="by use cases">
            By Use Cases
          </ToggleButton>
        </StyledToggleButtonGroup>

        <StyledTabs
          value={activeExampleTab}
          onChange={handleExampleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ mb: 2 }}
        >
          {Object.keys(viewMode === 'By Use Cases' ? useCasesData : teamData).map((tab) => (
            <Tab label={tab} value={tab} key={tab} />
          ))}
        </StyledTabs>
        <StyledCard>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ color: '#2980b9' }}>
              {activeExampleTab} - {maturityStage} Stage
            </Typography>
            <List>
              {(viewMode === 'By Use Cases' ? useCasesData[activeExampleTab] : teamData[activeExampleTab])[maturityStage].map((example, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon sx={{ color: '#27ae60' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={example} 
                    primaryTypographyProps={{ sx: { color: '#34495e' } }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </StyledCard>
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
          Category Scores
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(scores).filter(([key]) => key !== 'overall' && key !== 'ai_readiness').map(([category, score]) => (
            <Grid item xs={12} sm={6} md={4} key={category}>
              <StyledCard sx={{ p: 2 }}>
                <Typography variant="body1" gutterBottom sx={{ color: '#34495e' }}>
                  {category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </Typography>
                <Typography variant="h5" sx={{ color: '#e67e22' }}>
                  {score.toFixed(1)}%
                </Typography>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </StyledPaper>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <StyledButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AssessmentIcon />}
          onClick={handleViewInsights}
        >
          View Detailed Insights
        </StyledButton>
      </Box>
    </Box>
  );
}

export default AIQuestDashboard;
// src/contexts/AppContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
// import { sendChatMessage } from '../services/api';
import { v4 as uuidv4 } from 'uuid';
import { sendChatMessage, generateSummary as apiGenerateSummary, generateGoals as apiGenerateGoals, generateVisualization as apiGenerateVisualization, generateSyntheticData as apiGenerateSyntheticData, submitAIQuestResponseAPI } from '../services/api';
import { getAIQuestInsights } from '../services/api';


const AppContext = createContext();

export function AppProvider({ children }) {
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [chatSessions, setChatSessions] = useState([{ messages: [] }]);
  const [currentChatIndex, setCurrentChatIndex] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [llmConfig, setLlmConfig] = useState({
    provider: 'groq',
    model: 'llama-3.1-70b-versatile',
    temperature: 0.2,
    embedding_source: 'openai',
    vector_store_type: 'pinecone', // Add this line
  });

  const [lastSubmissionTimestamp, setLastSubmissionTimestamp] = useState(null);
  const [aiquestInsights, setAiquestInsights] = useState(null);
  const [aiquestResponses, setAiquestResponses] = useState({});
  const [useRagFusion, setUseRagFusion] = useState(false);
  const [vectorStoreType, setVectorStoreType] = useState('pinecone');
  const [file, setFile] = useState(null);
  const [columnDes, setColumnDes] = useState([]);
  const [summary, setSummary] = useState(null);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState('');
  const [visualization, setVisualization] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);


  const setOrgUser = (org, user) => {
    setSelectedOrganization(org);
    setSelectedUser(user);
    // Optionally, save to localStorage for persistence across page reloads
    localStorage.setItem('selectedOrganization', org);
    localStorage.setItem('selectedUser', user);
  };

  useEffect(() => {
    // Load saved org and user from localStorage on initial load
    const savedOrg = localStorage.getItem('selectedOrganization');
    const savedUser = localStorage.getItem('selectedUser');
    if (savedOrg && savedUser) {
      setSelectedOrganization(savedOrg);
      setSelectedUser(savedUser);
    }
  }, []);

  const handleSendMessage = async (inputMessage) => {
    if (inputMessage.trim() && selectedDatabase) {
      const currentSessionId = sessionId || uuidv4();
      setSessionId(currentSessionId);
      const newMessage = { text: inputMessage, sender: 'user' };
      
      setChatSessions(prevSessions => {
        const updatedSessions = [...prevSessions];
        updatedSessions[currentChatIndex] = {
          ...updatedSessions[currentChatIndex],
          messages: [...updatedSessions[currentChatIndex].messages, newMessage]
        };
        return updatedSessions;
      });
      
      try {
        const result = await sendChatMessage({
          query: inputMessage,
          database: selectedDatabase.title,
          session_id: currentSessionId,
          llm_config: {
            ...llmConfig,
            api_key: process.env.REACT_APP_GROQ_API_KEY,
          },
          use_rag_fusion: useRagFusion,
        });
        
        if (result && result.answer) {
          const aiResponse = { 
            text: result.answer, 
            sender: 'ai', 
            sources: result.sources || []
          };
          
          setChatSessions(prevSessions => {
            const updatedSessions = [...prevSessions];
            updatedSessions[currentChatIndex] = {
              ...updatedSessions[currentChatIndex],
              messages: [...updatedSessions[currentChatIndex].messages, aiResponse]
            };
            return updatedSessions;
          });
  
          if (result.session_id) {
            setSessionId(result.session_id);
          }
        } else {
          throw new Error('Invalid response structure from API');
        }
      } catch (error) {
        console.error('Error querying database:', error);
        setChatSessions(prevSessions => {
          const updatedSessions = [...prevSessions];
          updatedSessions[currentChatIndex] = {
            ...updatedSessions[currentChatIndex],
            messages: [
              ...updatedSessions[currentChatIndex].messages,
              {
                text: 'An error occurred. Please try again.',
                sender: 'system'
              }
            ]
          };
          return updatedSessions;
        });
      }
    }
  };

  // const submitAIQuestResponse = async (responses) => {
  //   try {
  //     console.log("Submitting new AIQuest responses:", responses);
  //     const result = await submitAIQuestResponseAPI(responses);
  //     console.log("Received result from submitAIQuestResponseAPI:", result);
  //     setAiquestResponses(responses);
  //     setAiquestInsights(result.insights);
  //     setLastSubmissionTimestamp(new Date().toISOString());
  //   } catch (error) {
  //     console.error('Error submitting AIQuest response:', error);
  //   }
  // };

  const submitAIQuestResponse = async (responses) => {
    try {
      console.log("Submitting new AIQuest responses:", responses);
      setAiquestResponses(responses);
      const result = await submitAIQuestResponseAPI(responses);
      console.log("Received result from submitAIQuestResponseAPI:", result);
      setAiquestInsights(result.insights);
      setLastSubmissionTimestamp(new Date().toISOString());
      return result;
    } catch (error) {
      console.error('Error submitting AIQuest response:', error);
      throw error;
    }
  };

  
  const onNewChat = (feature = 'smart-search') => {
    setChatSessions(prevSessions => [...prevSessions, { messages: [], feature }]);
    setCurrentChatIndex(chatSessions.length);
    setSessionId(null); // Reset the session ID for a new chat
  };

  const onSelectChat = (index) => {
    setCurrentChatIndex(index);
    const selectedChat = chatSessions[index];
    if (selectedChat.feature === 'smart-search') {
      setSelectedDatabase({ title: 'Smart Search' }); // Set an appropriate default database
    } else {
      setSelectedDatabase(null);
    }
  };
  const onDeleteChat = (index) => {
    setChatSessions(prevSessions => {
      const updatedSessions = prevSessions.filter((_, i) => i !== index);
      if (currentChatIndex === index) {
        setCurrentChatIndex(updatedSessions.length > 0 ? 0 : null);
      } else if (currentChatIndex > index) {
        setCurrentChatIndex(currentChatIndex - 1);
      }
      return updatedSessions;
    });
  };
  
  const generateSummary = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('columnDes', JSON.stringify(columnDes));
      
      const result = await apiGenerateSummary(formData);
      setSummary(result.summary);
      return result.summary;
    } catch (err) {
      setError('Failed to generate summary. Please try again.');
      console.error('Error generating summary:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const generateGoals = async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!summary) {
        throw new Error('Summary not available. Please generate summary first.');
      }
      const result = await apiGenerateGoals(summary);
      setGoals(result.goals);
      return result.goals;
    } catch (err) {
      setError('Failed to generate goals. Please try again.');
      console.error('Error generating goals:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const generateVisualization = async (goal) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!summary) {
        throw new Error('Summary not available. Please generate summary first.');
      }
      if (!goal) {
        throw new Error('No goal selected. Please select a goal first.');
      }
      const result = await apiGenerateVisualization(goal, summary);
      setVisualization(result.visualization);
      return result.visualization;
    } catch (err) {
      setError('Failed to generate visualization. Please try again.');
      console.error('Error generating visualization:', err);
    } finally {
      setIsLoading(false);
    }
  };
  
  const generateSyntheticData = async (data) => {
    setIsLoading(true);
    setError(null);
    try {
      let result;
      if (data.file) {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('type', data.type);
        result = await apiGenerateSyntheticData(formData);
      } else {
        result = await apiGenerateSyntheticData(data);
      }
      setSummary(result.summary);
      setColumnDes(result.columnDes);
      return result;
    } catch (err) {
      setError('Failed to generate synthetic data. Please try again.');
      console.error('Error generating synthetic data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  
  const value = {
    selectedDatabase,
    setSelectedDatabase,
    chatSessions,
    setChatSessions,
    currentChatIndex,
    setCurrentChatIndex,
    handleSendMessage,
    onNewChat,
    llmConfig,
    setLlmConfig,
    onDeleteChat,
    onSelectChat,
    sessionId,
    setSessionId,
    aiquestResponses,
    setAiquestResponses,  
    useRagFusion,
    setUseRagFusion,
    vectorStoreType,
    setVectorStoreType,
    file,
    setFile,
    columnDes,
    setColumnDes,
    summary,
    setSummary,
    goals,
    setGoals,
    selectedGoal,
    setSelectedGoal,
    visualization,
    setVisualization,
    generateSummary,
    generateGoals,
    generateVisualization,
    generateSyntheticData,
    aiquestInsights,
    setAiquestInsights,
    submitAIQuestResponse,
    lastSubmissionTimestamp,
    submitAIQuestResponse,
    selectedOrganization,
    selectedUser,
    setOrgUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}

export default AppProvider;
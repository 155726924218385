// src/components/features/AIQuest/RecommendationCard.js
import React from 'react';
import { Paper, Typography, Chip } from '@mui/material';

function RecommendationCard({ category, recommendation, priority }) {
  const priorityColor = {
    High: 'error',
    Medium: 'warning',
    Low: 'success',
  };

  return (
    <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>{category}</Typography>
      <Typography variant="body2" sx={{ flexGrow: 1 }}>{recommendation}</Typography>
      <Chip
        label={`Priority: ${priority}`}
        color={priorityColor[priority]}
        size="small"
        sx={{ alignSelf: 'flex-start', mt: 2 }}
      />
    </Paper>
  );
}

export default RecommendationCard;
// src/data/teamData.js

export const teamData = {
    'Sales': {
      Absent: [
        "Basic Lead Scoring: Simple AI to prioritize sales leads",
        "Automated Email Responses: AI-powered tool for quick email replies"
      ],
      Limited: [
        "Predictive Lead Generation: AI that identifies potential customers based on patterns",
        "Smart CRM Updater: AI assistant that helps maintain accurate customer data"
      ],
      Emergent: [
        "AI Sales Coach: Provides real-time advice during sales calls",
        "Intelligent Quote Generator: AI that creates personalized quotes based on customer profiles"
      ],
      Structured: [
        "AI-Driven Sales Forecasting: Advanced predictive models for sales projections",
        "Automated Contract Analyzer: AI that reviews and suggests improvements in contracts"
      ],
      Integrated: [
        "Holistic Customer Journey Optimizer: AI that personalizes entire customer experience",
        "AI Sales Strategy Planner: Develops data-driven sales strategies autonomously"
      ]
    },
    'Client Services': {
      Absent: [
        "Simple Chatbot: Basic AI for answering common customer queries",
        "Automated Ticket Categorizer: AI that sorts support tickets by type"
      ],
      Limited: [
        "Sentiment Analysis Tool: AI that gauges customer mood from text",
        "Smart FAQ Generator: AI-powered system that creates and updates FAQs based on common issues"
      ],
      Emergent: [
        "Predictive Customer Support: AI that anticipates customer issues before they occur",
        "Intelligent Ticket Router: AI system that assigns tickets to the most suitable agent"
      ],
      Structured: [
        "AI Customer Satisfaction Predictor: Forecasts satisfaction levels and suggests improvements",
        "Automated Solution Recommender: AI that suggests solutions based on past resolutions"
      ],
      Integrated: [
        "Proactive Customer Experience Manager: AI system that continuously optimizes customer interactions",
        "AI-Driven Customer Success Planner: Develops personalized strategies for each client's success"
      ]
    },
    'Product': {
      Absent: [
        "Basic Feature Tracker: Simple AI to monitor feature usage",
        "Automated Bug Reporter: AI tool that categorizes and reports bugs"
      ],
      Limited: [
        "User Behavior Analyzer: AI that identifies patterns in product usage",
        "Smart A/B Tester: AI-powered tool for optimizing A/B tests"
      ],
      Emergent: [
        "AI Product Roadmap Assistant: Helps prioritize features based on data",
        "Predictive User Experience Modeler: AI that simulates user interactions with new features"
      ],
      Structured: [
        "Autonomous Feature Optimizer: AI that continuously improves product features",
        "AI-Driven Product Strategy Planner: Develops data-backed product strategies"
      ],
      Integrated: [
        "Holistic Product Ecosystem Simulator: AI that models entire product ecosystem for strategic planning",
        "AI Product Innovator: Generates and validates new product ideas autonomously"
      ]
    },
    'Tech': {
      Absent: [
        "Simple Code Analyzer: Basic AI for identifying code issues",
        "Automated Test Case Generator: AI tool that creates basic test cases"
      ],
      Limited: [
        "Intelligent Code Reviewer: AI that provides suggestions during code reviews",
        "Smart Infrastructure Monitor: AI-powered system for monitoring tech infrastructure"
      ],
      Emergent: [
        "AI-Assisted Debugging: Helps developers identify and fix bugs more efficiently",
        "Predictive Capacity Planner: AI that forecasts infrastructure needs"
      ],
      Structured: [
        "Autonomous Code Optimizer: AI that refactors and optimizes code automatically",
        "AI Security Guardian: Continuously monitors and responds to security threats"
      ],
      Integrated: [
        "AI-Driven Tech Strategy Planner: Develops comprehensive tech strategies autonomously",
        "Self-Evolving System Architect: AI that designs and evolves system architecture"
      ]
    }
  };
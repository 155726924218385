// src/components/features/DataAnalyst/GoalSelector.js
import React from 'react';
import { Box, Typography, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';

const GoalSelector = ({ goals, rawGoals, selectedGoal, onSelectGoal, onGenerateVisualization, isGenerating }) => {
  const handleButtonClick = () => {
    const rawGoal = rawGoals.find(rawGoal => rawGoal.question === selectedGoal);
    if (rawGoal) {
      onGenerateVisualization(rawGoal);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Goals</Typography>
      <Select
        value={selectedGoal}
        onChange={(e) => onSelectGoal(e.target.value)}
        fullWidth
        displayEmpty
      >
        <MenuItem value="" disabled>Select a goal</MenuItem>
        {goals.map((goal, index) => (
          <MenuItem key={index} value={goal}>{goal}</MenuItem>
        ))}
      </Select>

      {selectedGoal && (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>Selected Goal Details:</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>{selectedGoal}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Visualization</TableCell>
                <TableCell>
                  {rawGoals.find(rawGoal => rawGoal.question === selectedGoal)?.visualization}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rationale</TableCell>
                <TableCell>
                  {rawGoals.find(rawGoal => rawGoal.question === selectedGoal)?.rationale}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box textAlign="right" mt={2}>
            <Button
              onClick={handleButtonClick}
              variant="contained"
              color="primary"
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating Visualizations...' : 'Generate Visualizations'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GoalSelector;
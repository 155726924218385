// src/components/features/SmartSearch/InfoDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

function InfoDialog({ open, onClose, database }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{database?.title} Database Details</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {database?.details}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
// src/components/features/SmartSearch/DatabaseInfo.js
import React, { useState } from 'react';
import { Box, Typography, IconButton, Button, Tooltip, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoDialog from './InfoDialog';

const databases = [
  { 
    title: 'Product', 
    description: 'AMA about product feature and releases',
    details: 'The Product database contains comprehensive information about our product features, release schedules, and development roadmap.'
  },
  { 
    title: 'Financials', 
    description: 'AMA about booked and pipeline revenues across clients',
    details: 'The Financials database houses all financial data related to our company operations.'
  },
  { 
    title: 'Marketing', 
    description: 'AMA about marketing and use cases to reference',
    details: 'The Marketing database is a rich repository of all marketing-related information.'
  },
  { 
    title: 'Research', 
    description: 'AMA about new initiatives',
    details: 'The Research database encompasses all ongoing and completed research projects and new initiatives.'
  },
];

function DatabaseInfo({ database, onUploadClick, onInfoClick, onInstructionsClick, onDatabaseSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDatabaseChange = (newDatabase) => {
    onDatabaseSelect(newDatabase);
    handleClose();
  };

  const handleInfoClick = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoClose = () => {
    setInfoDialogOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ mr: 2 }}>
          {database.title}
        </Typography>
        <IconButton onClick={handleClick}>
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {databases.map((db) => (
            <MenuItem key={db.title} onClick={() => handleDatabaseChange(db)}>
              {db.title}
            </MenuItem>
          ))}
        </Menu>
        <Tooltip title="View database details">
          <IconButton onClick={handleInfoClick}>
            <InfoIcon sx={{ color: '#C8326B' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Button 
          startIcon={<HelpOutlineIcon />} 
          onClick={onInstructionsClick}
          sx={{ 
            color: 'text.secondary',
            mr: 2
          }}
        >
          Instructions
        </Button>
        <Button 
          startIcon={<AddIcon />} 
          onClick={onUploadClick}
          sx={{ 
            color: 'white', 
            bgcolor: '#C8326B',
            '&:hover': { bgcolor: '#A62A59' }
          }}
        >
          Update database
        </Button>
      </Box>
      <InfoDialog open={infoDialogOpen} onClose={handleInfoClose} database={database} />
    </Box>
  );
}

export default DatabaseInfo;
// src/services/api.js
import axios from 'axios';

let refreshTokenFunction = null;


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.setRefreshTokenFunction = (refreshFunction) => {
  refreshTokenFunction = refreshFunction;
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // try {
      //   // Implement a token refresh mechanism here if your backend supports it
      //   // For now, we'll just redirect to login
      //   window.location.href = '/login';
      //   return Promise.reject(error);
      // } catch (refreshError) {
      //   window.location.href = '/login';
      //   return Promise.reject(refreshError);
      // }
      if (refreshTokenFunction) {
        try {
          const newToken = await refreshTokenFunction();
          if (newToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
            return api(originalRequest);
          }
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
        }
      }
    }
    return Promise.reject(error);
  }
);

export const sendChatMessage = async (messageData) => {
  try {
    const queryRequest = {
      query: messageData.query,
      database: messageData.database,
      session_id: messageData.session_id,
      llm_config: {
        provider: messageData.llm_config.provider,
        model: messageData.llm_config.model,
        temperature: messageData.llm_config.temperature,
        embedding_source: messageData.llm_config.embedding_source,
        vector_store_type: messageData.llm_config.vector_store_type,
      },
      use_rag_fusion: messageData.use_rag_fusion,
    };

    console.log('Sending query request:', queryRequest);

    const response = await api.post('/api/query', queryRequest);
    console.log('Raw API response:', response);
    return response.data;
  } catch (error) {
    console.error('Error sending chat message:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
    }
    throw error;
  }
};

export const uploadFile = async (formData) => {
  try {
    const response = await api.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.detail || 'An error occurred during upload');
    } else if (error.request) {
      throw new Error('No response received from the server');
    } else {
      throw new Error('Error setting up the request');
    }
  }
};

export const clearChat = async (sessionId) => {
  try {
    const response = await api.post('/api/clear_chat', { session_id: sessionId });
    return response.data;
  } catch (error) {
    console.error('Error clearing chat:', error);
    throw error;
  }
};



// export const submitAIQuestResponse = async (data) => {
//   try {
//     const token = localStorage.getItem('token'); // Or however you're storing the token
//     const response = await api.post('/api/aiquest/submit', data, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error submitting AIQuest response:', error);
//     throw error;
//   }
// };

// export const submitAIQuestResponse = async (responses) => {
//   try {
//     const response = await api.post('/api/aiquest/submit', responses);
//     return response.data;
//   } catch (error) {
//     console.error('Error submitting AIQuest response:', error);
//     throw error;
//   }
// };

export const submitAIQuestResponseAPI = async (responses) => {
  try {
    console.log("Sending AIQuest responses to backend:", responses);
    const response = await api.post('/api/aiquest/submit', responses);
    console.log("Received response from backend:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting AIQuest response:', error);
    throw error;
  }
};

export const generateSummary = async (fileData) => {
  try {
    const response = await api.post('/api/generate-summary', fileData);
    return response.data;
  } catch (error) {
    console.error('Error generating summary:', error);
    throw error;
  }
};

export const generateGoals = async (summary) => {
  try {
    const response = await api.post('/api/generate-goals', { summary });
    return response.data;
  } catch (error) {
    console.error('Error generating goals:', error);
    throw error;
  }
};

export const generateVisualization = async (goal, summary) => {
  try {
    const response = await api.post('/api/generate-visualization', { goal, summary });
    return response.data;
  } catch (error) {
    console.error('Error generating visualization:', error);
    throw error;
  }
};


export const generateSyntheticData = async (data) => {
  let response;
  if (data instanceof FormData) {
    response = await api.post('/api/generate-synthetic-data', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } else {
    response = await api.post('/api/generate-synthetic-data', data);
  }
  return response.data;
};

// export const getAIQuestInsights = async () => {
//   try {
//     const response = await api.get('/api/aiquest/insights');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching AIQuest insights:', error);
//     if (error.response && error.response.status === 401) {
//       // Handle unauthorized error (e.g., redirect to login page)
//       throw new Error('Unauthorized. Please log in again.');
//     }
//     throw error;
//   }
// };

// export const getOrganizationAIQuestInsights = async (organizationId) => {
//   try {
//     const response = await api.get(`/api/aiquest/organization/${organizationId}/insights`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching organization AIQuest insights:', error);
//     throw error;
//   }
// };


export const getOrganizations = async () => {
  try {
    const response = await api.get('/api/aiquest/user/organizations');
    return response.data;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};

export const getOrganizationInterviewees = async (orgName) => {
  try {
    console.log(`Fetching interviewees for organization: ${orgName}`);
    const response = await api.get(`/api/aiquest/organizations/${encodeURIComponent(orgName)}/interviewees`);
    console.log('Received interviewees:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching interviewees:', error);
    throw error;
  }
};

export const getInterviewees = async (organizationId) => {
  try {
    const response = await api.get(`/organizations/${organizationId}/interviewees`);
    return response.data;
  } catch (error) {
    console.error('Error fetching interviewees:', error);
    throw error;
  }
};

export const submitAIQuestResponse = async (response) => {
  try {
    console.log('Submitting AIQuest response:', JSON.stringify(response, null, 2)); // Add this line for debugging
    const res = await api.post('/aiquest/submit', response);
    console.log('AIQuest submission response:', res.data); // Add this line for debugging
    return res.data;
  } catch (error) {
    console.error('Error submitting AIQuest response:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
    }
    throw error;
  }
};

export const getAIQuestUserData = async () => {
  try {
    const response = await api.get('/api/aiquest/user-data');
    return response.data;
  } catch (error) {
    console.error('Error fetching AIQuest user data:', error);
    throw error;
  }
};


// export const getAIQuestInsights = async (organization, interviewee) => {
//   try {
//     console.log(`Fetching insights for org: ${organization}, interviewee: ${interviewee}`);
//     let response = await api.get('/api/aiquest/insights', {
//       params: { 
//         organization: organization,
//         interviewee: interviewee
//       }
//     });
    
//     if (response.data && response.data.insights) {
//       console.log('Insights found:', response.data);
//       return response.data;
//     } else {
//       console.log('No insights found, fetching response and generating insights');
//       response = await api.get('/api/aiquest/responses', {
//         params: {
//           organization: organization,
//           interviewee: interviewee
//         }
//       });
      
//       if (response.data && response.data.response) {
//         const generatedInsights = await api.post('/api/aiquest/submit', response.data.response);
//         console.log('Generated insights:', generatedInsights.data);
//         return generatedInsights.data;
//       } else {
//         throw new Error('No response data found');
//       }
//     }
//   } catch (error) {
//     console.error('Error in getAIQuestInsights:', error);
//     throw error;
//   }
// };

export const getAIQuestInsights = async (organization, interviewee) => {
  try {
    console.log(`Fetching insights for org: ${organization}, interviewee: ${interviewee}`);
    const response = await api.get('/api/aiquest/insights', {
      params: { 
        organization: organization,
        interviewee: interviewee
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in getAIQuestInsights:', error);
    throw error;
  }
};



export const getAIQuestSavedResponse = async (org, user) => {
  try {
    const response = await api.get('/api/aiquest/saved-response', {
      params: { organization: org, user: user }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching AIQuest saved response:', error);
    if (error.response && error.response.data && error.response.data.detail) {
      throw new Error(error.response.data.detail);
    }
    throw error;
  }
};



export const getOrganizationAIQuestInsights = async (organizationIdentifier) => {
  try {
    const response = await api.get(`/api/aiquest/organization/${organizationIdentifier}/insights`);
    return response.data;
  } catch (error) {
    console.error('Error fetching organization AIQuest insights:', error);
    throw error;
  }
};

export const getUserOrganizationsAndInterviewees = async () => {
  try {
    const response = await api.get('/api/aiquest/user-organizations-and-interviewees');
    return response.data;
  } catch (error) {
    console.error('Error fetching user organizations and interviewees:', error);
    throw error;
  }
};

export const generateMCQQuestions = async (painPointsDescription, organizationName, intervieweeName) => {
  try {
    const response = await api.post('/aiquest/generate-mcq-questions', {
      pain_points_description: painPointsDescription,
      organization_name: organizationName,
      interviewee_name: intervieweeName
    });
    return response.data;
  } catch (error) {
    console.error('Error generating MCQ questions:', error);
    throw error;
  }
};


export const processMCQAnswers = async (processId, mcqAnswers, organizationId, intervieweeId) => {
  try {
    const response = await api.post('/aiquest/process-mcq-answers', {
      process_id: processId,
      mcq_answers: mcqAnswers,
      organization_id: organizationId,
      interviewee_id: intervieweeId
    });
    return response.data;
  } catch (error) {
    console.error('Error processing MCQ answers:', error);
    throw error;
  }
};

export const getIdeaGenerationHistory = async (organizationName, intervieweeName) => {
  try {
    const response = await api.get('/aiquest/idea-generation-history', {
      params: { organization_name: organizationName, interviewee_name: intervieweeName }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching idea generation history:', error);
    throw error;
  }
};

export const deleteIdeaGenerationProcess = async (processId) => {
  try {
    const response = await api.delete(`/aiquest/idea-generation-process/${processId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting idea generation process:', error);
    throw error;
  }
};

export const getCompleteProcess = async (processId) => {
  try {
    const response = await api.get(`/aiquest/complete-process/${processId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching complete process:', error);
    throw error;
  }
};

export const updateIdeaGenerationProcess = async (processId, updates) => {
  try {
    const response = await api.post(`/aiquest/update-process/${processId}`, updates);
    return response.data;
  } catch (error) {
    console.error('Error updating idea generation process:', error);
    throw error;
  }
};

export const getAIQuestDashboardData = async (organization, interviewee) => {
  try {
    const response = await api.get('/api/aiquest/dashboard-data', {
      params: { organization_name: organization, interviewee: interviewee }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching AIQuest dashboard data:', error);
    throw error;
  }
};



export default api;

// checkpoints
// src/components/features/SmartSearch/InstructionsPopup.js
import React from 'react';
import { Popover, Box, Typography } from '@mui/material';
import { useAppContext } from '../../../contexts/AppContext';

function InstructionsPopup({ anchorEl, onClose }) {
  const { selectedDatabase } = useAppContext();

  const instructions = [
    selectedDatabase ? `You've selected the ${selectedDatabase.title} database.` : 'Select a database from the dropdown menu.',
    "Type your question in the chat box at the bottom of the page.",
    "Press Enter or click the Send button to submit your question.",
    "The AI will process your query and display the answer here.",
    "To switch databases, click on the dropdown arrow next to the database name.",
    "To add new information to the database, click the \"Update database\" button."
  ];

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2, maxWidth: 400 }}>
        <Typography variant="h6" gutterBottom>How to use this page:</Typography>
        {instructions.map((step, index) => (
          <Typography key={index} variant="body2" sx={{ mb: 1 }}>
            {index + 1}. {step}
          </Typography>
        ))}
      </Box>
    </Popover>
  );
}

export default InstructionsPopup;
// src/components/features/DataAnalyst/SyntheticDataSection.js
import React, { useState } from 'react';
import { TextField, Button, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import FileUploadSection from './FileUploadSection';

function SyntheticDataSection({ isLoading, onGenerate, onNext }) {
  const [inputType, setInputType] = useState('sample');
  const [file, setFile] = useState(null);
  const [columnNames, setColumnNames] = useState('');
  const [dataDescription, setDataDescription] = useState('');

  const handleGenerate = async () => {
    let data;
    if (inputType === 'sample' || inputType === 'dictionary') {
      data = { file, type: inputType };
    } else {
      data = { columnNames, dataDescription };
    }
    await onGenerate(data);
    onNext();
  };

  return (
    <Box>
      <RadioGroup
        value={inputType}
        onChange={(e) => setInputType(e.target.value)}
      >
        <FormControlLabel value="sample" control={<Radio />} label="Upload sample file" />
        <FormControlLabel value="dictionary" control={<Radio />} label="Upload data dictionary" />
        <FormControlLabel value="manual" control={<Radio />} label="Enter column names manually" />
      </RadioGroup>

      {(inputType === 'sample' || inputType === 'dictionary') && (
        <FileUploadSection
          file={file}
          setFile={setFile}
          isLoading={isLoading}
          onNext={() => {}}
        />
      )}

      {inputType === 'manual' && (
        <>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            label="Enter column names (comma-separated)"
            value={columnNames}
            onChange={(e) => setColumnNames(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Describe the data for more context"
            value={dataDescription}
            onChange={(e) => setDataDescription(e.target.value)}
            margin="normal"
          />
        </>
      )}

      <Button
        variant="contained"
        onClick={handleGenerate}
        disabled={isLoading || (!file && !columnNames)}
      >
        {isLoading ? 'Generating...' : 'Generate Data'}
      </Button>
    </Box>
  );
}

export default SyntheticDataSection;
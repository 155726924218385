// src/components/features/AIQuest/AIQuestLanding.js
import React from 'react';
import { Box, Typography, Grid, Paper, Button, Container } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import WorkIcon from '@mui/icons-material/Work';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Link } from 'react-router-dom';

const AIQuestLanding = () => {
  const tiles = [
    {
      icon: <AssessmentIcon fontSize="large" />,
      title: "AI Readiness",
      description: "Evaluate your organization's readiness for AI adoption and identify key focus areas."
    },
    {
      icon: <DataUsageIcon fontSize="large" />,
      title: "Data Quality Analysis",
      description: "Assess your data infrastructure and quality to ensure it's AI-ready."
    },
    {
      icon: <WorkIcon fontSize="large" />,
      title: "Workflow Optimization",
      description: "Analyze current workflows and identify AI integration opportunities."
    },
    {
      icon: <TimelineIcon fontSize="large" />,
      title: "Custom AI Roadmap",
      description: "Receive a tailored AI implementation plan with clear, actionable steps."
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom align="center">
          AIQuest: Your AI Transformation Journey
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" color="text.secondary">
          Unlock the power of AI for your organization with our expert-guided approach
        </Typography>
        
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {tiles.map((tile, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                sx={{
                  p: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
                elevation={2}
              >
                <Box sx={{ color: 'primary.main', mb: 2 }}>{tile.icon}</Box>
                <Typography variant="h6" component="h3" gutterBottom>
                  {tile.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {tile.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            How AIQuest Works
          </Typography>
          <Typography variant="body1" paragraph>
            Our expert team guides you through a comprehensive 3-week process:
          </Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Week 1</Typography>
                <Typography variant="body2">
                  Surveys and interviews with key stakeholders to understand your unique needs and challenges.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Week 2</Typography>
                <Typography variant="body2">
                  Analysis of existing data sets and schemas to assess AI readiness and potential.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 3, height: '100%' }}>
                <Typography variant="h6" gutterBottom>Week 3</Typography>
                <Typography variant="body2">
                  Mapping of current state workflows and identification of key tasks for AI optimization.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Your AIQuest Deliverables
          </Typography>
          <Typography variant="body1" paragraph>
            Receive a comprehensive AI adoption strategy tailored to your organization:
          </Typography>
          <ul style={{ textAlign: 'left', maxWidth: '600px', margin: 'auto' }}>
            <li>Multi-tier investment level documentation</li>
            <li>Custom PowerPoint presentation with actionable AI paths and possibilities</li>
            <li>AI application wireframes demonstrating potential solutions</li>
            <li>Data quality analysis report</li>
            <li>Workflow optimization recommendations</li>
          </ul>
        </Box>
        
        <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Button 
            component={Link} 
            to="/aiquest/survey" 
            variant="contained" 
            size="large" 
            color="primary"
          >
            Start Your AIQuest Journey
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AIQuestLanding;
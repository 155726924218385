// src/components/features/AIQuest/IdeaGeneration/utils.js
import { styled } from '@mui/material/styles';
import { Paper, Card, Chip } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TimelineIcon from '@mui/icons-material/Timeline';

export const colorPalette = {
  primary: '#C8326B',
  secondary: '#D3437B',
  accent1: '#FF6B6B',
  accent2: '#FFA06B',
  accent3: '#FFD56B',
  accent4: '#B2FF6B',
  accent5: '#6BFF8A',
};

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: 'white',
  boxShadow: theme.shadows[3],
}));

export const StyledCard = styled(Card)(({ theme, severity }) => ({
    height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    flexGrow: 1,
  },
    marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderLeft: `5px solid ${getSeverityColor(severity, theme)}`,
}));

export const StyledChip = styled(Chip)(({ theme, bgcolor }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: bgcolor || theme.palette.primary.main,
  color: theme.palette.getContrastText(bgcolor || theme.palette.primary.main),
}));

export const sections = [
  { key: 'pain_point_identification', title: 'Pain Points', icon: <PriorityHighIcon />, color: colorPalette.accent1 },
  { key: 'root_cause_analysis', title: 'Root Cause Analysis', icon: <AssignmentIcon />, color: colorPalette.accent2 },
  { key: 'idea_generation', title: 'Generated Ideas', icon: <LightbulbIcon />, color: colorPalette.accent3 },
  { key: 'solution_mapping', title: 'Solution Mapping', icon: <TrendingUpIcon />, color: colorPalette.accent4 },
  { key: 'feasibility_assessment', title: 'Feasibility Assessment', icon: <CheckCircleOutlineIcon />, color: colorPalette.accent5 },
  { key: 'impact_scoring', title: 'Impact Scoring', icon: <TrendingUpIcon />, color: colorPalette.primary },
  { key: 'prioritization_matrix', title: 'Prioritization Matrix', icon: <PriorityHighIcon />, color: colorPalette.secondary },
  { key: 'action_planning', title: 'Action Planning', icon: <TimelineIcon />, color: colorPalette.accent1 },
];

export function getSeverityColor(severity) {
  switch (severity) {
    case 'high':
      return colorPalette.accent1;
    case 'medium':
      return colorPalette.accent3;
    case 'low':
      return colorPalette.accent5;
    default:
      return colorPalette.primary;
  }
}
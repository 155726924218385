// src/components/features/AIQuest/AIQuestSavedResponses.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Chip, Grid, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppContext } from '../../../contexts/AppContext';
import api from '../../../services/api';
import OrgUserSelector from './OrgUserSelector';
import { getAIQuestSavedResponse } from '../../../services/api';

function AIQuestSavedResponses() {
  const { aiquestResponses } = useAppContext();
  const [savedResponses, setSavedResponses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { selectedOrganization, selectedUser } = useAppContext();

  useEffect(() => {
    if (selectedOrganization && selectedUser) {
      fetchSavedResponses(selectedOrganization, selectedUser);
    }
  }, [selectedOrganization, selectedUser]);

  const fetchSavedResponses = async (org, user) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getAIQuestSavedResponse(org, user);
      setSavedResponses(response.responses);
    } catch (error) {
      setError(`Failed to fetch saved responses: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  // useEffect(() => {
  //   const fetchSavedResponses = async () => {
  //     try {
  //       const response = await api.get('/api/aiquest/user-data');
  //       if (response.data && response.data.responses) {
  //         setSavedResponses(response.data.responses);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching saved responses:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchSavedResponses();
  // }, []);

  const handleOrgUserSelect = async (org, user) => {
    setLoading(true);
    setError(null);
    try {
      if (!org || !user) {
        throw new Error("Both organization and user must be selected");
      }
      const response = await getAIQuestSavedResponse(org, user);
      if (response && response.responses) {
        setSavedResponses(response.responses);
      } else {
        setSavedResponses([]);
        setError('No saved responses found for the selected organization and user.');
      }
    } catch (error) {
      console.error('Error fetching saved responses:', error);
      setError(`Failed to fetch saved responses: ${error.message}`);
      setSavedResponses(null);
    } finally {
      setLoading(false);
    }
  };

  const renderUserDetails = () => {
    if (!aiquestResponses.userDetails) return null;

    const details = aiquestResponses.userDetails;
    return (
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>User Details</Typography>
        <Grid container spacing={2}>
          {Object.entries(details).map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <Typography variant="subtitle2">{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
              <Typography variant="body1">{value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  };

  const groupResponses = (responses) => {
    return responses.reduce((acc, response) => {
      const category = response.questionId.split('_')[0];
      if (!acc[category]) acc[category] = [];
      acc[category].push(response);
      return acc;
    }, {});
  };


  const groupedResponses = savedResponses ? groupResponses(savedResponses) : null;

  // const groupedResponses = aiquestResponses.responses?.reduce((acc, response) => {
  //   const category = response.questionId.split('_')[0];
  //   if (!acc[category]) acc[category] = [];
  //   acc[category].push(response);
  //   return acc;
  // }, {});

  const getCategoryTitle = (category) => {
    switch (category) {
      case 'ai': return 'AI Readiness';
      case 'strategy': return 'Strategy';
      case 'culture': return 'Culture';
      case 'process': return 'Process';
      case 'outcomes': return 'Outcomes';
      default: return category.charAt(0).toUpperCase() + category.slice(1);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box sx={{ p: 4, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom align="center">Your AIQuest Responses</Typography>
      <OrgUserSelector />
      <Typography variant="subtitle1" gutterBottom align="center" color="text.secondary">
        Review and reflect on your AI readiness assessment
      </Typography>

      {error && (
      <Typography color="error" align="center" sx={{ mt: 2, mb: 2 }}>
        {error}
      </Typography>
    )}

      {renderUserDetails()}
      
      {groupedResponses && Object.keys(groupedResponses).length > 0 ? (
        Object.entries(groupedResponses).map(([category, responses]) => (
          <Accordion key={category} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{getCategoryTitle(category)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {responses.map((response) => (
                <Box key={response.questionId} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    {response.questionText}
                  </Typography>
                  <Chip 
                    label={response.answerText} 
                    color="primary" 
                    variant="outlined" 
                    sx={{ mt: 1 }}
                  />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No saved responses available. Complete the AIQuest survey to see your responses here.</Typography>
        </Paper>
      )}
    </Box>
  );
}

export default AIQuestSavedResponses;
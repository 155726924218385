// src/components/features/AIQuest/IdeaGeneration/AIQuestPlannerHome.js

import React from 'react';
import { Box, Typography, Button, Paper, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const TimelineContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 1200,
  margin: '0 auto',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: 6,
    backgroundColor: theme.palette.primary.main,
    top: 0,
    bottom: 0,
    left: '50%',
    marginLeft: -3,
    zIndex: 0,
  },
}));

const TimelineItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  position: 'relative',
  width: '42%',
  marginBottom: theme.spacing(4), // Reduced from 8 to 4
  zIndex: 1,
  '&:nth-of-type(odd)': {
    marginRight: '58%',
    '&::after': {
      left: 'auto',
      right: '-15px',
      borderWidth: '10px 0 10px 10px',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
  '&:nth-of-type(even)': {
    marginLeft: '58%',
    '&::after': {
      left: '-15px',
      borderWidth: '10px 10px 10px 0',
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    width: 0,
    height: 0,
    border: '15px solid transparent',
    borderColor: 'transparent',
    marginTop: -15,
  },
}));

const ActionButton = styled(Button)(({ theme, position }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [position]: '-140px',
  whiteSpace: 'nowrap',
  width: '160px',
  zIndex: 2,
}));

const AIQuestPlannerHome = ({ onStartIdeaGeneration }) => {
  const sections = [
    {
      title: 'Learn Your AI Readiness',
      description: 'WISSP.AI helps you discover your organization\'s readiness and maturity for AI adoption. Our comprehensive assessment helps you understand where you stand and where to focus.',
      icon: <LightbulbIcon fontSize="large" color="primary" />,
      free: true,
      button: (
        <ActionButton
          component={Link}
          to="/aiquest/insights"
          variant="contained"
          color="primary"
          position="right"
        >
          View AI Readiness
        </ActionButton>
      ),
    },
    {
      title: 'Generate Ideas where AI can create value',
      description: 'Our IDEA WISSP helps you suggest ideas to your pain points, quick wins and strategic planning solutions tailored to your business objectives.',
      icon: <EmojiObjectsIcon fontSize="large" color="primary" />,
      free: true,
      button: (
        <ActionButton
          onClick={onStartIdeaGeneration}
          variant="contained"
          color="success"
          position="left"
          sx={{
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            '&:hover': {
              background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            },
          }}
        >
          Build Your Roadmap
        </ActionButton>
      ),
    },
    {
      title: 'Strategic Human Intelligence and Execution',
      description: 'WISSP.AI provides expert guidance to turn ideas into reality. Our team helps prioritize initiatives, develop roadmaps, and execute strategies for maximum impact.',
      icon: <TrendingUpIcon fontSize="large" color="primary" />,
      free: false,
      button: (
        <ActionButton
          href="mailto:get-started@wissp.ai"
          variant="contained"
          color="secondary"
          position="right"
        >
          Reach Out to Us
        </ActionButton>
      ),
    },
  ];

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 4 }}>
      <Typography variant="h3" gutterBottom align="center" color="primary" sx={{ mb: 2 }}>
        AIQuest Planner
      </Typography>
      <Typography variant="h5" gutterBottom align="center" color="text.secondary" sx={{ mb: 4 }}>
        Accelerate Your AI Journey
      </Typography>

      <TimelineContainer>
        {sections.map((section, index) => (
          <Fade in={true} timeout={1000 * (index + 1)} key={index}>
            <TimelineItem elevation={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {section.icon}
                <Typography variant="h6" sx={{ ml: 1 }}>
                  {section.title}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {section.description}
              </Typography>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  color: section.free ? 'success.main' : 'primary.main',
                  fontWeight: 'bold',
                }}
              >
                {section.free ? 'Free' : 'Premium'}
              </Typography>
              {section.button}
            </TimelineItem>
          </Fade>
        ))}
      </TimelineContainer>
    </Box>
  );
};

export default AIQuestPlannerHome;
// src/components/features/DataAnalyst/DataAnalystPage.js
import React, { useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Card, CardContent, Grid, Button } from '@mui/material';
import FileUploadSection from './FileUploadSection';
import SyntheticDataSection from './SyntheticDataSection';
import ResultsTable from './ResultsTable';
import GoalSelector from './GoalSelector';
import VisualizationDisplay from './VisualizationDisplay';
import CustomGoalForm from './CustomGoals';
import SummaryTable from './SummaryTable';
import PredefinedDatasets from './PredefinedDatasets';
import { useAppContext } from '../../../contexts/AppContext';
import DataSummarySection from './DataSummarySection';
import VisualizationSection from './VisualizationSection';


function DataAnalystPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [dataSource, setDataSource] = useState(null); // 'upload', 'synthetic', or 'predefined'
  const { 
    file, 
    setFile,
    columnDes, 
    setColumnDes, 
    summary, 
    setSummary, 
    goals, 
    setGoals, 
    selectedGoal, 
    setSelectedGoal, 
    visualization, 
    setVisualization, 
    isLoading, 
    generateSummary, 
    generateGoals, 
    generateVisualization,
    generateSyntheticData,
    setIsLoading,
    llmConfig,
  } = useAppContext();


  const steps = [
    'Choose Data Source',
    'Upload/Generate Data',
    'Data Summary & Exploration',
    'Goal Selection',
    'Visualization'
  ];

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const handleStepClick = (step) => setActiveStep(step);
  const handleDataSourceSelect = (source) => {
    setDataSource(source);
    handleNext();
  };

  const handleFileUpload = async (uploadedFile) => {
    setFile(uploadedFile);
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', uploadedFile);
    
    try {
      const response = await fetch('/api/data-analyst/upload', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setSummary(data);
      handleNext();
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateGoals = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/data-analyst/goals', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ summary, n: 5 }),
      });
      const data = await response.json();
      setGoals(data);
      handleNext();
    } catch (error) {
      console.error('Error generating goals:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateVisualization = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('summary', JSON.stringify(summary));
    formData.append('goal', JSON.stringify(selectedGoal));

    try {
      const response = await fetch('/api/data-analyst/visualize', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setVisualization(data);
      handleNext();
    } catch (error) {
      console.error('Error generating visualization:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button fullWidth variant="contained" onClick={() => handleDataSourceSelect('upload')}>
                Upload Your Data
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth variant="contained" onClick={() => handleDataSourceSelect('synthetic')}>
                Generate Synthetic Data
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth variant="contained" onClick={() => handleDataSourceSelect('predefined')}>
                Use Predefined Dataset
              </Button>
            </Grid>
          </Grid>
        );
        case 1:
          if (dataSource === 'upload') {
            return <FileUploadSection onFileUpload={handleFileUpload} isLoading={isLoading} />;
          } else if (dataSource === 'synthetic') {
            return <SyntheticDataSection isLoading={isLoading} onGenerate={generateSyntheticData} onNext={handleNext} />;
          } else {
            return <PredefinedDatasets onSelect={(dataset) => { setFile(dataset); handleNext(); }} />;
          }
        case 2:
          return <DataSummarySection summary={summary} onNext={handleGenerateGoals} />;
        case 3:
          return <GoalSelector goals={goals} selectedGoal={selectedGoal} onSelectGoal={setSelectedGoal} onNext={handleGenerateVisualization} />;
        case 4:
          return <VisualizationSection visualization={visualization} />;
        default:
          return null;
      }
    };

    return (
      <Box sx={{ p: 4, height: '100%', overflow: 'auto' }}>
        <Typography variant="h4" gutterBottom>AI Data Analyst</Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleStepClick(index)} sx={{ cursor: 'pointer' }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Card sx={{ mt: 4 }}>
          <CardContent>{renderStep()}</CardContent>
        </Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={handleBack} disabled={activeStep === 0}>Back</Button>
          <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>Next</Button>
        </Box>
      </Box>
    );
  }

export default DataAnalystPage;
// src/components/features/AIQuest/OrgUserSelector.js

import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { useAppContext } from '../../../contexts/AppContext';
import { getUserOrganizationsAndInterviewees } from '../../../services/api';

function OrgUserSelector() {
  const { selectedOrganization, selectedUser, setOrgUser } = useAppContext();
  const [organizations, setOrganizations] = useState([]);
  const [interviewees, setInterviewees] = useState({});

  useEffect(() => {
    fetchOrganizationsAndInterviewees();
  }, []);

  const fetchOrganizationsAndInterviewees = async () => {
    try {
      const data = await getUserOrganizationsAndInterviewees();
      if (data && data.organizations) {
        setOrganizations(Object.keys(data.organizations).map(name => ({ name })));
        setInterviewees(data.organizations);
      }
    } catch (error) {
      console.error('Error fetching organizations and interviewees:', error);
    }
  };

  const handleOrgChange = (event) => {
    const orgName = event.target.value;
    setOrgUser(orgName, null);
  };

  const handleUserChange = (event) => {
    const user = event.target.value;
    setOrgUser(selectedOrganization, user);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <FormControl fullWidth>
        <InputLabel>Organization</InputLabel>
        <Select 
          value={selectedOrganization || ''}
          onChange={handleOrgChange}
          label="Organization"
        >
          {organizations.map((org) => (
            <MenuItem key={org.name} value={org.name}>{org.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>User</InputLabel>
        <Select 
          value={selectedUser || ''}
          onChange={handleUserChange}
          label="User"
          disabled={!selectedOrganization}
        >
          {interviewees[selectedOrganization] && interviewees[selectedOrganization].map((user) => (
            <MenuItem key={user} value={user}>{user}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default OrgUserSelector;
// src/components/features/AIQuest/IdeaGeneration/PainPointsForm.js
import React from 'react';
import { Typography, TextField, Button, CircularProgress } from '@mui/material';
import { StyledPaper } from './utils';

function PainPointsForm({ painPoints, setPainPoints, onSubmit, loading }) {
  return (
    <StyledPaper>
      <Typography variant="h6" gutterBottom color="primary">
        Describe Your Pain Points
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        value={painPoints}
        onChange={(e) => setPainPoints(e.target.value)}
        placeholder="Describe the challenges or pain points your organization is facing..."
        sx={{ mb: 2, width: '100%' }}  // Add some bottom margin
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={!painPoints.trim() || loading}
        // sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </StyledPaper>
  );
}

export default PainPointsForm;
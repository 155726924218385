// src/components/Login.js
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Box, Typography, Paper, Grid, Link, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '20px',
  overflow: 'hidden',
  maxWidth: '800px',
  width: '100%',
}));

const LogoSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  color: 'white',
  height: '100%',
}));

const FormSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
}));

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await login(username, password);
      if (success) {
        const from = location.state?.from?.pathname || "/";
        navigate(from);
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (e.g., show error message to user)
    }
  };

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      p: 2,
    }}>
      <StyledPaper elevation={3}>
        <Grid container sx={{ height: isMobile ? 'auto' : '500px' }}>
          <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <LogoSection>
              <Typography variant="h4" component="h1" gutterBottom>
                WISSP.AI
              </Typography>
              <Typography variant="body2">
                Login to your account to continue
              </Typography>
            </LogoSection>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormSection>
              <Typography variant="h5" component="h2" gutterBottom>
                Welcome
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Sign in to your account to continue
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Typography variant="body2" align="center">
                  Don't have an account? <Link href="/register" underline="hover">Sign Up</Link>
                </Typography>
              </Box>
            </FormSection>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
}

export default Login;
// src/components/AIQuestSurvey.js
import React, { useState, useEffect, useRef } from 'react';
import WisspLogo from '../logo.svg';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormGroup,
  IconButton,
  Tooltip,
  CircularProgress
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { aiquestQuestions } from '../data/aiquest_questions';
import { useNavigate } from 'react-router-dom';
import AIQuestUserDetails from './AIQuestUserDetails';
import AIMaturityDashboard from './features/AIQuest/AIMaturityDashboard';
import AIInsightsSection from './features/AIQuest/AIInsightsSection';
import { useAppContext } from '../contexts/AppContext';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';

function AIQuestSurvey() {
  const [activeStep, setActiveStep] = useState(0);
  const [responses, setResponses] = useState({});
  const navigate = useNavigate();
  const { aiquestResponses, setAiquestResponses } = useAppContext();
  const { submitAIQuestResponse } = useAppContext();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [organizations, setOrganizations] = useState([]);
  const [interviewees, setInterviewees] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedInterviewee, setSelectedInterviewee] = useState(null);
  const { setOrgUser } = useAppContext();
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeStep]);
  
  

  const sections = ['User Details', 'AIReadiness', 'Strategy', 'Culture', 'Process', 'Outcomes'];
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    organization: '',
    industryVertical: '',
    title: '',
    organizationRevenue: ''
  });

  const handleUserDetailsChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleResponseChange = (questionId, value) => {
    setResponses({ ...responses, [questionId]: value });
  };

  const fetchSavedResponse = async () => {
    try {
      if (!user) {
        console.log("No user logged in");
        setLoading(false);
        return;
      }
      console.log("Fetching saved response for user:", user.username);
      const response = await api.get('/api/aiquest/user-data', {
        params: { username: user.username }
      });
      console.log("Received response:", response.data);
      if (response.data && response.data.userDetails) {
        setUserDetails(response.data.userDetails);
        if (response.data.responses && response.data.responses.length > 0) {
          const savedResponses = response.data.responses.reduce((acc, r) => {
            acc[r.questionId] = r.answerValue;
            return acc;
          }, {});
          setResponses(savedResponses);
        } else {
          setResponses({});
        }
      } else {
        // New user or no data, reset everything
        setUserDetails({
          name: '',
          email: '',
          organization: '',
          industryVertical: '',
          title: '',
          organizationRevenue: ''
        });
        setResponses({});
      }
    } catch (error) {
      console.error('Error fetching saved response:', error);
      // Reset data on error
      setUserDetails({
        name: '',
        email: '',
        organization: '',
        industryVertical: '',
        title: '',
        organizationRevenue: ''
      });
      setResponses({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchSavedResponse();
    }
  }, [user]);

  useEffect(() => {
    if (selectedOrganization) {
      fetchInterviewees(selectedOrganization);
    }
  }, [selectedOrganization]);

  const fetchOrganizations = async () => {
    try {
      const response = await api.get('/user/organizations');
      setOrganizations(response.data);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const fetchInterviewees = async (orgId) => {
    try {
      const response = await api.get(`/organizations/${orgId}/interviewees`);
      setInterviewees(response.data);
    } catch (error) {
      console.error('Error fetching interviewees:', error);
    }
  };
  

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    const surveyData = {
      userDetails: userDetails,
      responses: Object.entries(responses).map(([id, value]) => {
        const [category, questionNumber] = id.split('_');
        const question = aiquestQuestions[category]?.find(q => q.id === id);
        
        return {
          questionId: id,
          questionText: question ? question.text : `Unknown question (${id})`,
          answerValue: value,
          answerText: getAnswerText(question, value)
        };
      })
    };

    try {
      console.log("Submitting survey data:", JSON.stringify(surveyData, null, 2));
      const result = await submitAIQuestResponse(surveyData);
      console.log("Received result:", result);
      if (result && result.insights) {
        setAiquestResponses(result);

        // Add these two lines to update the selected organization and user
        setOrgUser(userDetails.organization, userDetails.name);
        
        navigate('/aiquest/insights', { 
          state: { 
            organizationName: userDetails.organization, 
            intervieweeName: userDetails.name 
          } 
        });
      } else {
        throw new Error('No insights received from submission');
      }
    } catch (error) {
      console.error('Error submitting AIQuest response:', error);
      alert('An error occurred while submitting the survey. Please try again.');
    }
  };
  
  const getAnswerText = (question, value) => {
    if (!question) return String(value);
  
    if (question.type === 'mcq') {
      return question.options.find(opt => opt.value === value)?.label || value;
    } else if (question.type === 'multiselect') {
      if (Array.isArray(value)) {
        return value.map(v => question.options.find(opt => opt.value === v)?.label || v).join(', ');
      }
      return String(value);
    }
    return String(value);
  };
  
  // Helper function to get question text
  const getQuestionText = (questionId) => {
    const [category, number] = questionId.split('_');
    const questionData = aiquestQuestions[category]?.find(q => q.id === questionId);
    return questionData ? questionData.text : `Question ${number}`;
  };


  // Update the getAnswerText function
// const getAnswerText = (question, value) => {
//   if (question.type === 'mcq') {
//     return question.options.find(opt => opt.value === value)?.label || value;
//   } else if (question.type === 'multiselect') {
//     if (Array.isArray(value)) {
//       return value.map(v => question.options.find(opt => opt.value === v)?.label || v).join(', ');
//     } else {
//       return String(value);
//     }
//   } else {
//     return String(value);
//   }
// };

const handleUserDetailsSubmit = (details) => {
  if (details.name !== userDetails.name) {
    // New user, clear responses
    setResponses({});
  }
  setUserDetails(details);
  handleNext();
};



  const renderQuestion = (question) => {
    if (!question) {
      return null;
    }

    switch (question.type) {
      case 'mcq':
        return (
          <FormControl component="fieldset" key={question.id} sx={{ mb: 4 }}>
            <FormLabel component="legend" sx={{ mb: 2, fontWeight: 'bold' }}>
              {question.text}
            </FormLabel>
            <RadioGroup
              value={responses[question.id] || ''}
              onChange={(e) => setResponses({ ...responses, [question.id]: e.target.value })}
            >
              {question.options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{ mb: 1 }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case 'multiselect':
        return (
          <FormControl component="fieldset" key={question.id} sx={{ mb: 4 }}>
            <FormLabel component="legend" sx={{ mb: 2, fontWeight: 'bold' }}>
              {question.text}
            </FormLabel>
            <FormGroup>
              {question.options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={responses[question.id]?.includes(option.value) || false}
                      onChange={(e) => {
                        const currentResponses = responses[question.id] || [];
                        const newResponses = e.target.checked
                          ? [...currentResponses, option.value]
                          : currentResponses.filter((value) => value !== option.value);
                        setResponses({ ...responses, [question.id]: newResponses });
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        );
      case 'freetext':
        return (
          <TextField
            key={question.id}
            fullWidth
            multiline
            rows={4}
            label={question.text}
            value={responses[question.id] || ''}
            onChange={(e) => setResponses({ ...responses, [question.id]: e.target.value })}
            sx={{ mb: 4 }}
          />
        );
      default:
        return <Typography key={question.id}>Unsupported question type.</Typography>;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }


  return (
    <Box sx={{ p: 4, maxWidth: 800, mx: 'auto' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4">AIQuest Survey</Typography>
        <Tooltip title="AIQuest is a comprehensive survey to assess your organization's AI readiness. It covers various aspects such as strategy, culture, and processes to provide you with valuable insights and recommendations.">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Stepper activeStep={activeStep - 1} sx={{ mb: 4 }}>
        {sections.slice(1).map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper elevation={3} sx={{ p: 4, bgcolor: '#f8f9fa' }} ref={topRef}>
        {activeStep === 0 ? (
          <AIQuestUserDetails onNext={handleUserDetailsSubmit} initialName={userDetails.name} />
        ) : (
          aiquestQuestions[sections[activeStep].toLowerCase().replace(' ', '_')]?.map(renderQuestion)
        )}
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, position: 'sticky', bottom: 0, bgcolor: 'background.paper', p: 2 }}>
        <Button onClick={handleBack} disabled={activeStep === 0}>
          Back
        </Button>
        {activeStep === sections.length - 1 ? (
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <Button variant="contained" onClick={handleNext}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default AIQuestSurvey;
// checkpoint
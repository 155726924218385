// src/components/features/AIQuest/CompanyInsights.js

import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper, Grid, CircularProgress, Button, Select, MenuItem, 
  FormControl, InputLabel, Card, CardContent, LinearProgress, Stepper, Step, 
  StepLabel, StepContent, ToggleButton, ToggleButtonGroup, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { getOrganizations, getOrganizationAIQuestInsights } from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../services/api';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BuildIcon from '@mui/icons-material/Build';
import TimelineIcon from '@mui/icons-material/Timeline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// checkpoint 
const colorPalette = {
  primary: '#C8326B',
  secondary: '#D3437B',
  accent1: '#FF6B6B',
  accent2: '#FFA06B',
  accent3: '#FFD56B',
  accent4: '#B2FF6B',
  accent5: '#6BFF8A',
  darkGreen: '#2E7D32',
  darkYellow: '#F9A825',
};


const StyledCard = styled(Card)(({ theme, color }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
  backgroundColor: color,
  color: 'white',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: theme.shadows[3],
}));

const StatCard = ({ title, value, icon, color }) => (
  <StyledCard color={color}>
    <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        {icon}
      </Box>
      <Typography variant="h4" component="div" sx={{ mt: 2 }}>
        {value}
      </Typography>
    </CardContent>
  </StyledCard>
);

const InsightSection = ({ title, content, icon, color }) => (
  <StyledPaper elevation={3} sx={{ p: 3, mt: 3 }}>
    <Box display="flex" alignItems="center" mb={2}>
      {React.cloneElement(icon, { style: { color: color, fontSize: 30 } })}
      <Typography variant="h6" ml={1} color={color}>{title}</Typography>
    </Box>
    {typeof content === 'string' ? (
      <Typography variant="body1">{content}</Typography>
    ) : (
      content
    )}
  </StyledPaper>
);



function CompanyInsights() {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [activeMaturityView, setActiveMaturityView] = useState('roles');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    fetchOrganizations();
  }, []);


  useEffect(() => {
    if (selectedOrganization) {
      fetchInsights(selectedOrganization);
    }
  }, [selectedOrganization]);



  const fetchOrganizations = async () => {
    try {
      const orgs = await getOrganizations();
      setOrganizations(orgs);
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setError('Failed to fetch organizations. Please try again later.');
    }
  };



  const fetchInsights = async (orgName) => {
    setLoading(true);
    setError(null);
    try {
      const result = await getOrganizationAIQuestInsights(orgName);
      setInsights(result);
    } catch (error) {
      console.error('Error fetching organization AIQuest insights:', error);
      setError('Failed to fetch insights. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleOrganizationChange = (event) => {
    setSelectedOrganization(event.target.value);
  };


  const handleDropdownSelect = (org) => {
    fetchInsights(org);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const renderGoals = () => (
    <StyledPaper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom color={colorPalette.primary}>AI Maturity Goals</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" color={colorPalette.accent2} gutterBottom>Short-term Goals</Typography>
          <List dense>
            {insights.recommendations_and_roadmap.short_term_goals.map((goal, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <ArrowRightAltIcon fontSize="small" style={{ color: colorPalette.accent2 }} />
                </ListItemIcon>
                <ListItemText primary={goal} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" color={colorPalette.accent4} gutterBottom>Long-term Goals</Typography>
          <List dense>
            {insights.recommendations_and_roadmap.long_term_goals.map((goal, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <ArrowRightAltIcon fontSize="small" style={{ color: colorPalette.accent4 }} />
                </ListItemIcon>
                <ListItemText primary={goal} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </StyledPaper>
  );



  
  // const handleOrganizationChange = async (event) => {
  //   const orgId = event.target.value;
  //   setSelectedOrganization(orgId);
  //   if (orgId) {
  //     setLoading(true);
  //     try {
  //       const response = await api.get(`/api/aiquest/organization/${orgId}/insights`);
  //       setInsights(response.data);
  //       setError(null);
  //     } catch (err) {
  //       console.error('Error fetching company insights:', err);
  //       setError('Failed to fetch company insights. Please try again later.');
  //       setInsights(null);
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     setInsights(null);
  //   }
  // };

  const handleMaturityViewChange = (event, newView) => {
    if (newView !== null) {
      setActiveMaturityView(newView);
    }
  };

  const handleTakeSurvey = () => {
    navigate('/aiquest/survey');
  };

  const renderMaturitySection = () => {
    let data;
    let title;

    switch (activeMaturityView) {
      case 'roles':
        data = insights.maturity_by_roles;
        title = "Maturity by Roles";
        break;
      case 'levels':
        data = insights.maturity_by_organizational_levels;
        title = "Maturity by Organizational Levels";
        break;
      case 'priorities':
        data = insights.maturity_by_priorities;
        title = "Maturity by Priorities";
        break;
      case 'functions':
        data = insights.maturity_by_function;
        title = "Maturity by Function";
        break;
      case 'tech':
        data = insights.maturity_by_technology_adoption;
        title = "Maturity by Technology Adoption";
        break;
      default:
        data = {};
        title = "";
    }


    return (
      <StyledPaper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom color={colorPalette.primary}>{title}</Typography>
        <Grid container spacing={2}>
          {Object.entries(data).map(([key, value]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="body1" sx={{ flexGrow: 1, fontWeight: 'bold', color: colorPalette.primary }}>{key}</Typography>
                <Typography variant="body1" color={colorPalette.secondary}>
                  {typeof value === 'number' ? `${value.toFixed(1)}%` : 
                   (typeof value.score === 'number' ? `${value.score.toFixed(1)}%` : 'N/A')}
                </Typography>
              </Box>
              <LinearProgress 
                variant="determinate" 
                value={typeof value === 'number' ? value : (typeof value.score === 'number' ? value.score : 0)} 
                sx={{ height: 10, borderRadius: 5, mb: 2 }}
              />
              {value.strengths && (
                <List dense>
                  {value.strengths.map((strength, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon style={{ color: colorPalette.accent5 }} />
                      </ListItemIcon>
                      <ListItemText primary={strength} />
                    </ListItem>
                  ))}
                </List>
              )}
              {value.weaknesses && (
                <List dense>
                  {value.weaknesses.map((weakness, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <ErrorOutlineIcon style={{ color: colorPalette.accent1 }} />
                      </ListItemIcon>
                      <ListItemText primary={weakness} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          ))}
        </Grid>
      </StyledPaper>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom color={colorPalette.primary}>
        Company AI Readiness Insights
      </Typography>

      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="organization-select-label">Select Organization</InputLabel>
        <Select
          labelId="organization-select-label"
          id="organization-select"
          value={selectedOrganization}
          label="Select Organization"
          // onChange={handleOrganizationChange}
          onChange={(e) => setSelectedOrganization(e.target.value)}
        >
          <MenuItem value="">
            <em>Select an organization</em>
          </MenuItem>
          {Array.from(new Set(organizations.map(org => org.name))).map((orgName) => (
    <MenuItem key={orgName} value={orgName}>{orgName}</MenuItem>
  ))}
        </Select>
      </FormControl>

      {error && (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" color="error" gutterBottom>{error}</Typography>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </Box>
      )}

      {!selectedOrganization && (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>Please select an organization to view insights.</Typography>
          <Typography variant="body1" gutterBottom>If you haven't completed the AIQuest survey, please take it first.</Typography>
          <Button variant="contained" color="primary" onClick={handleTakeSurvey} sx={{ mt: 2 }}>
            Take AIQuest Survey
          </Button>
        </Box>
      )}

      {selectedOrganization && (!insights || Object.keys(insights).length === 0) && (
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>No insights available for the selected organization.</Typography>
          <Typography variant="body1" gutterBottom>Please make sure the organization has completed the AIQuest survey.</Typography>
          <Button variant="contained" color="primary" onClick={handleTakeSurvey} sx={{ mt: 2 }}>
            Take AIQuest Survey
          </Button>
        </Box>
      )}

{insights && Object.keys(insights).length > 0 && (
        <>
          <StyledPaper elevation={3} sx={{ p: 3, mb: 4, bgcolor: colorPalette.secondary, color: 'white' }}>
            <Typography variant="h5" gutterBottom>Overall Company AI Readiness</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="h3" component="div" sx={{ mr: 2 }}>
                {insights.executive_summary?.overall_score?.toFixed(2) || 'N/A'}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={insights.executive_summary?.overall_score || 0}
                sx={{ flexGrow: 1, height: 10, borderRadius: 5, bgcolor: 'rgba(255,255,255,0.3)', '& .MuiLinearProgress-bar': { bgcolor: 'white' } }}
              />
            </Box>
          </StyledPaper>

          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard 
                title="Participants" 
                value={insights.executive_summary?.participant_count || 'N/A'} 
                icon={<GroupIcon fontSize="large" />}
                color={colorPalette.accent1}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard 
                title="Roles Involved" 
                value={insights.executive_summary?.role_count || 'N/A'} 
                icon={<WorkIcon fontSize="large" />}
                color={colorPalette.accent2}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard 
                title="Departments" 
                value={insights.executive_summary?.department_count || 'N/A'} 
                icon={<BusinessIcon fontSize="large" />}
                color={colorPalette.accent3}
              />
            </Grid>
          </Grid>

          <InsightSection 
            title="Executive Summary" 
            content={insights.executive_summary?.summary || 'No summary available'}
            icon={<DashboardIcon />}
            color={colorPalette.primary}
          />

          {insights.executive_summary?.key_strengths && (
            <InsightSection 
              title="Key Strengths" 
              content={
                <List>
                  {insights.executive_summary.key_strengths.map((strength, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon fontSize="small" style={{ color: colorPalette.darkGreen }} />
                      </ListItemIcon>
                      <ListItemText primary={strength} />
                    </ListItem>
                  ))}
                </List>
              }
              icon={<TrendingUpIcon />}
              color={colorPalette.darkGreen}
            />
          )}

          {insights.executive_summary?.areas_for_improvement && (
            <InsightSection 
              title="Areas for Improvement" 
              content={
                <List>
                  {insights.executive_summary.areas_for_improvement.map((area, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <ErrorOutlineIcon fontSize="small" style={{ color: colorPalette.accent1 }} />
                      </ListItemIcon>
                      <ListItemText primary={area} />
                    </ListItem>
                  ))}
                </List>
              }
              icon={<BuildIcon />}
              color={colorPalette.accent1}
            />
          )}

          <StyledPaper elevation={3} sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" gutterBottom color={colorPalette.primary}>AI Maturity Analysis</Typography>
            <StyledToggleButtonGroup
              value={activeMaturityView}
              exclusive
              onChange={handleMaturityViewChange}
              aria-label="maturity view"
              sx={{ mb: 2 }}
            >
              <ToggleButton value="roles" aria-label="roles">
                Roles
              </ToggleButton>
              <ToggleButton value="levels" aria-label="levels">
                Org Levels
              </ToggleButton>
              <ToggleButton value="priorities" aria-label="priorities">
                Priorities
              </ToggleButton>
              <ToggleButton value="functions" aria-label="functions">
                Functions
              </ToggleButton>
              <ToggleButton value="tech" aria-label="tech">
                Technology
              </ToggleButton>
            </StyledToggleButtonGroup>
            {renderMaturitySection()}
          </StyledPaper>

          {insights.cross_cutting_insights && (
            <InsightSection 
              title="Cross-cutting Insights" 
              content={
                <List>
                  {insights.cross_cutting_insights.map((insight, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <InfoOutlinedIcon fontSize="small" style={{ color: colorPalette.darkYellow }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={insight.insight}
                        secondary={
                          <>
                            <Typography component="span" variant="body2" color="text.primary">
                              Impact: 
                            </Typography>
                            {" " + insight.impact}
                            <br />
                            <Typography component="span" variant="body2" color="text.primary">
                              Recommendation: 
                            </Typography>
                            {" " + insight.recommendation}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              }
              icon={<TimelineIcon />}
              color={colorPalette.darkYellow}
            />
          )}

          
          {insights.recommendations_and_roadmap && renderGoals()}
          
          {insights.recommendations_and_roadmap && (
            <StyledPaper elevation={3} sx={{ p: 3, mt: 3 }}>
              <Box display="flex" alignItems="center" mb={2}>
                <TimelineIcon style={{ color: colorPalette.primary }} />
                <Typography variant="h6" ml={1} color={colorPalette.primary}>AI Maturity Roadmap</Typography>
              </Box>
              <Stepper orientation="vertical">
                {insights.recommendations_and_roadmap.key_initiatives.map((initiative, index) => (
                  <Step key={index} active={true}>
                    <StepLabel>{initiative.name}</StepLabel>
                    <StepContent>
                      <Typography>{initiative.description}</Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        Timeline: {initiative.timeline}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Expected Impact: {initiative.expected_impact}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </StyledPaper>
          )}

          {insights.recommendations_and_roadmap && (
            <>
              {/* <InsightSection 
                title="Short-term Goals" 
                content={
                  <List>
                    {insights.recommendations_and_roadmap.short_term_goals.map((goal, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <TrendingUpIcon style={{ color: colorPalette.accent2 }} />
                        </ListItemIcon>
                        <ListItemText primary={goal} />
                      </ListItem>
                    ))}
                  </List>
                }
                icon={<TrendingUpIcon />}
                color={colorPalette.accent2}
              />
              <InsightSection 
                title="Long-term Goals" 
                content={
                  <List>
                    {insights.recommendations_and_roadmap.long_term_goals.map((goal, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <TimelineIcon style={{ color: colorPalette.accent4 }} />
                        </ListItemIcon>
                        <ListItemText primary={goal} />
                      </ListItem>
                    ))}
                  </List>
                }
                icon={<TimelineIcon />}
                color={colorPalette.accent4}
              /> */}
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default CompanyInsights;                          
// src/components/common/UserMenu.js
import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography, ListItemIcon } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArchiveIcon from '@mui/icons-material/Archive';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function UserMenu({ onOpenSettings }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    handleClose();
    onOpenSettings('general');
  };

  const handleProfileClick = () => {
    handleClose();
    onOpenSettings('profile');
  };

  const handleLogout = () => {
    handleClose();
    logout();
    navigate('/login');
  };

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          width: 30,
          height: 30,
          bgcolor: '#C8326B',
          color: 'white',
          minWidth: 'auto',
          '&:hover': { bgcolor: '#A62A59' },
        }}
      >
        <Typography variant="caption">{user?.username?.substring(0, 2).toUpperCase() || 'U'}</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Profile</Typography>
        </MenuItem>
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Archived Chats</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AdminPanelSettingsIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Admin Panel</Typography>
        </MenuItem>
        <Box sx={{ my: 1, borderTop: '1px solid #e0e0e0' }} />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Sign Out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
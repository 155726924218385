// src/data/aiquest_questions.js

export const aiquestQuestions = {
    aireadiness: [
      {
        id: "aireadiness_q1",
        text: "Your approach to solving a problem?",
        type: "mcq",
        options: [
          { value: "A", label: "Focuses on data collection and storage." },
          { value: "B", label: "Emphasizes actionable insights from data." }
        ]
      },
      {
        id: "aireadiness_q2",
        text: "What would you describe as business priority?",
        type: "mcq",
        options: [
          { value: "A", label: "Prioritizes data quality and governance." },
          { value: "B", label: "Values data visualization and storytelling" }
        ]
      },
      {
        id: "aireadiness_q3",
        text: "What's more important?",
        type: "mcq",
        options: [
          { value: "A", label: "Invests in data infrastructure and security." },
          { value: "B", label: "Invests in analytics and AI tools." }
        ]
      },
      {
        id: "aireadiness_q4",
        text: "Strategy for developing new capabilities or solutions?",
        type: "mcq",
        options: [
          { value: "A", label: "Hires data engineers and architects." },
          { value: "B", label: "Focuses on the people involved." }
        ]
      },
      {
        id: "aireadiness_q5",
        text: "How do you evaluate a new solution?",
        type: "mcq",
        options: [
          { value: "A", label: "Prioritizes integration with existing systems." },
          { value: "B", label: "Focuses on business impact and ROI." }
        ]
      },
      {
        id: "aireadiness_q6",
        text: "What describes your team's priority?",
        type: "mcq",
        options: [
          { value: "A", label: "Emphasizes reliability and scalability." },
          { value: "B", label: "Values speed and agility in implementation." }
        ]
      },
      {
        id: "aireadiness_q7",
        text: "How do you go about business decision making?",
        type: "mcq",
        options: [
          { value: "A", label: "Follows a structured evaluation process." },
          { value: "B", label: "Makes decisions based on pilot results." }
        ]
      },
      {
        id: "aireadiness_q8",
        text: "Preference towards new products or solutions",
        type: "mcq",
        options: [
          { value: "A", label: "Prefers tried-and-true solutions." },
          { value: "B", label: "Open to innovative and cutting-edge approaches." }
        ]
      }
    ],
    strategy: [
      {
        id: "strategy_q1",
        text: "How well-defined is your company's data and AI strategy?",
        type: "mcq",
        options: [
          { value: "0", label: "Not defined" },
          { value: "1", label: "Partially defined" },
          { value: "2", label: "Defined but not communicated" },
          { value: "3", label: "Clearly defined and communicated" }
        ]
      },
      {
        id: "strategy_q2",
        text: "When are AI initiatives part of the process? These may include building new features, content creation, or prototype testing",
        type: "mcq",
        options: [
          { value: "0", label: "AI is not part of the process" },
          { value: "1", label: "AI is involved independently for a few projects" },
          { value: "2", label: "AI is evaluated for feature prioritization and work planning" },
          { value: "3", label: "AI work is always part of early project planning, at least to scope whether it's needed" }
        ]
      },
      {
        id: "strategy_q3",
        text: "Do you have a team of AI experts in house?",
        type: "mcq",
        options: [
          { value: "0", label: "We do not have AI experts in-house" },
          { value: "1", label: "We have limited AI expertise in-house" },
          { value: "2", label: "We have individuals with AI skills, but not a dedicated team" },
          { value: "3", label: "We have a dedicated team of AI experts for all requirements" }
        ]
      }
    ],
    culture: [
      {
        id: "culture_q1",
        text: "Rate availability and accessibility of data?",
        type: "mcq",
        options: [
          { value: "0", label: "Data quality issues impact accessibility" },
          { value: "1", label: "Access to data is limited" },
          { value: "2", label: "Data is somewhat accessible but improvements are needed" },
          { value: "3", label: "Data is highly accessible and readily available" }
        ]
      },
      {
        id: "culture_q2",
        text: "How open is your organization to adopting AI technologies?",
        type: "mcq",
        options: [
          { value: "0", label: "Very resistant" },
          { value: "1", label: "Somewhat resistant" },
          { value: "2", label: "Neutral" },
          { value: "3", label: "Open" },
          { value: "4", label: "Very open" }
        ]
      },
      {
        id: "culture_q3",
        text: "How does the company encourage people in Data and AI roles to grow their careers?",
        type: "mcq",
        options: [
          { value: "0", label: "No formal Data and AI roles exist." },
          { value: "1", label: "Few or no Data and AI roles exist, but some employees are self-motivated and add Data and AI work on top of their core responsibilities" },
          { value: "2", label: "Data and AI role(s) may exist, but there are only a few Data and AI professionals in the organization." },
          { value: "3", label: "Job profiles and career paths for Data and AI roles are well-defined and tracked throughout the organization" }
        ]
      },
      {
        id: "culture_q4",
        text: "How does your company view AI today?",
        type: "mcq",
        options: [
          { value: "0", label: "AI is not considered or recognized" },
          { value: "1", label: "AI is used in specific areas but not integrated" },
          { value: "2", label: "AI is being explored for potential benefits" },
          { value: "3", label: "AI is seen as a strategic advantage" }
        ]
      }
    ],
    process: [
      {
        id: "process_q1",
        text: "How is AI initiative tied to goals of your org?",
        type: "mcq",
        options: [
          { value: "0", label: "AI initiatives are not explicitly linked to organization goals" },
          { value: "1", label: "AI initiatives have limited impact on organizational goals" },
          { value: "2", label: "AI initiatives have some alignment with organizational goals" },
          { value: "3", label: "AI initiatives are closely aligned with organizational goals" }
        ]
      },
      {
        id: "process_q2",
        text: "How do non tech roles (Sales, Marketing, Client Services, etc) perceive AI?",
        type: "mcq",
        options: [
          { value: "0", label: "No Data and AI work is done and/or nobody talks about Data and AI." },
          { value: "1", label: "Data and AI is noted but not widely understood or supported" },
          { value: "2", label: "Some individuals in such roles are curious and want to be involved, but most are not aware" },
          { value: "3", label: "Most individuals accept and support AI, but the amount of support varies throughout the organization." },
          { value: "4", label: "Everyone has strong respect for Data and AI work alongside their stakeholders" }
        ]
      },
      {
        id: "process_q3",
        text: "How structured are your data collection and management processes?",
        type: "mcq",
        options: [
          { value: "0", label: "Ad hoc" },
          { value: "1", label: "Partially structured" },
          { value: "2", label: "Structured but not optimized" },
          { value: "3", label: "Optimized" }
        ]
      },
      {
        id: "process_q4",
        text: "How well-defined are your data governance and compliance policies?",
        type: "mcq",
        options: [
          { value: "0", label: "Non-existent" },
          { value: "1", label: "Partially defined" },
          { value: "2", label: "Defined but not enforced" },
          { value: "3", label: "Enforced and monitored" }
        ]
      }
    ],
    outcomes: [
      {
        id: "outcomes_q1",
        text: "Have your org solved problems without big data/AI?",
        type: "mcq",
        options: [
          { value: "0", label: "Big data/AI is integral to problem-solving" },
          { value: "1", label: "Organization has had limited success without AI" },
          { value: "2", label: "Organization has had some success without AI, but challenges persist" },
          { value: "3", label: "Organization has successfully solved problems without AI" }
        ]
      },
      {
        id: "outcomes_q2",
        text: "How do you measure the impact of data initiatives?",
        type: "mcq",
        options: [
          { value: "0", label: "There are no defined metrics for measuring impact" },
          { value: "1", label: "Impact measurement is limited" },
          { value: "2", label: "Some impact metrics are measured, but not comprehensive" },
          { value: "3", label: "Impact is measured with clear and defined metrics" }
        ]
      },
      {
        id: "outcomes_q3",
        text: "Please share any final comments about how Data and AI is done in your organization.",
        type: "freetext"
      },
      {
        id: "outcomes_q4",
        text: "Typical areas/problems/opportunities that you see.",
        type: "multiselect",
        options: [
          { value: "unlock_value", label: "Unlocking more value out of data" },
          { value: "optimized_planning", label: "Optimized planning - win more new business with improved Audiences, Tactics & forecasted pacing with our model recommendations" }
        ]
      }
    ]
  };
// src/components/features/DataAnalyst/VisualizationSection.js
import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Select, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppContext } from '../../../contexts/AppContext';

function VisualizationSection({ onGenerate, visualization, onNext }) {
  const [goal, setGoal] = useState('');
  const [grammar, setGrammar] = useState('altair');
  const [infographic, setInfographic] = useState(null);
  const [explanation, setExplanation] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [repairedVisualization, setRepairedVisualization] = useState(null);
  const [recommendations, setRecommendations] = useState(null);

  const { 
    generateInfographics, 
    performVizOps, 
    explainVisualization, 
    evaluateVisualization, 
    repairVisualization, 
    recommendVisualizations 
  } = useAppContext();

  const handleGenerate = async () => {
    const result = await onGenerate(goal, grammar);
    // Assume onGenerate updates the visualization in the context
  };

  const handleGenerateInfographic = async () => {
    const result = await generateInfographics(visualization);
    setInfographic(result);
  };

  const handleExplain = async () => {
    const result = await explainVisualization(visualization);
    setExplanation(result);
  };

  const handleEvaluate = async () => {
    const result = await evaluateVisualization(visualization);
    setEvaluation(result);
  };

  const handleRepair = async () => {
    const result = await repairVisualization(visualization);
    setRepairedVisualization(result);
  };

  const handleRecommend = async () => {
    const result = await recommendVisualizations(visualization);
    setRecommendations(result);
  };

  return (
    <Box>
      <Typography variant="h6">AI-driven Visualization</Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Generate Visualization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            label="Visualization Goal"
            value={goal}
            onChange={(e) => setGoal(e.target.value)}
            margin="normal"
          />
          <Select
            value={grammar}
            onChange={(e) => setGrammar(e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="altair">Altair</MenuItem>
            <MenuItem value="matplotlib">Matplotlib</MenuItem>
            <MenuItem value="seaborn">Seaborn</MenuItem>
            <MenuItem value="ggplot2">ggplot2 (R)</MenuItem>
          </Select>
          <Button onClick={handleGenerate} disabled={!goal}>Generate</Button>
          {visualization && (
            <Box mt={2}>
              <Typography variant="subtitle1">Generated Visualization:</Typography>
              <pre>{visualization}</pre>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Infographics Generation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button onClick={handleGenerateInfographic} disabled={!visualization}>Generate Infographic</Button>
          {infographic && <img src={infographic} alt="Generated Infographic" />}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Visualization Explanation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button onClick={handleExplain} disabled={!visualization}>Explain Visualization</Button>
          {explanation && <Typography>{explanation}</Typography>}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Self-Evaluation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button onClick={handleEvaluate} disabled={!visualization}>Evaluate Visualization</Button>
          {evaluation && <Typography>{evaluation}</Typography>}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Visualization Repair</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button onClick={handleRepair} disabled={!visualization}>Repair Visualization</Button>
          {repairedVisualization && (
            <Box mt={2}>
              <Typography variant="subtitle1">Repaired Visualization:</Typography>
              <pre>{repairedVisualization}</pre>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Visualization Recommendations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button onClick={handleRecommend} disabled={!visualization}>Get Recommendations</Button>
          {recommendations && recommendations.map((rec, index) => (
            <Typography key={index}>{rec}</Typography>
          ))}
        </AccordionDetails>
      </Accordion>

      <Button onClick={onNext} disabled={!visualization}>Next</Button>
    </Box>
  );
}

export default VisualizationSection;
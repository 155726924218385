// src/components/features/AIQuest/AIQuestSidebar.js
import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import SaveIcon from '@mui/icons-material/Save';
import LandscapeIcon from '@mui/icons-material/Landscape';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessIcon from '@mui/icons-material/Business';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

function AIQuestSidebar() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const menuItems = [
    { text: 'AIQuest Home', icon: <LandscapeIcon />, path: '/aiquest' },
    { text: 'Take Survey', icon: <AssessmentIcon />, path: '/aiquest/survey' },
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/aiquest/dashboard' },
    { text: 'Insights', icon: <InsightsIcon />, path: '/aiquest/insights' },
    { text: 'Company Insights', icon: <BusinessIcon />, path: '/aiquest/company-insights' },
    { text: 'Saved Responses', icon: <SaveIcon />, path: '/aiquest/saved-responses' },
    { text: 'AIQuest Planner', icon: <LightbulbIcon />, path: '/aiquest/idea-generation' },
  ];

  return (
    <Box sx={{ width: 250, flexShrink: 0, borderRight: '1px solid #e0e0e0', p: 2, bgcolor: '#f5f5f5', height: '100vh' }}>
      <Typography variant="h6" component="div" sx={{ mb: 4, color: '#C8326B', fontWeight: 'bold' }}>
        WISSP.AI
      </Typography>
      <List>
        {menuItems.map((item) => (
          <ListItem 
            button 
            component={Link} 
            to={item.path} 
            key={item.text}
            sx={{
              bgcolor: isActive(item.path) ? '#C8326B' : 'transparent',
              color: isActive(item.path) ? 'white' : 'inherit',
              '&:hover': {
                bgcolor: isActive(item.path) ? '#C8326B' : '#e0e0e0',
              },
              borderRadius: '4px',
              mb: 1,
            }}
          >
            <ListItemIcon sx={{ color: isActive(item.path) ? 'white' : 'inherit' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default AIQuestSidebar;
// src/components/AIQuestUserDetails.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const AIQuestUserDetails = ({ onNext }) => {
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    organization: '',
    industryVertical: '',
    title: '',
    organizationRevenue: '',
  });

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(userDetails);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" align="center" sx={{ mb: 2 }}>
        AIQuest
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ mb: 4 }}>
        Assess Current State, Risk Management, Strategic Planning, and Priorities
      </Typography>
      <Paper elevation={3} sx={{ p: 4, bgcolor: '#f8f9fa' }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Get Started
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={userDetails.name}
            onChange={handleChange}
            required
            margin="normal"
            placeholder="Please provide your full name"
          />
          <TextField
            fullWidth
            label="Email ID"
            name="email"
            type="email"
            value={userDetails.email}
            onChange={handleChange}
            required
            margin="normal"
            placeholder="name@example.com"
          />
          <TextField
            fullWidth
            label="Organization"
            name="organization"
            value={userDetails.organization}
            onChange={handleChange}
            required
            margin="normal"
            placeholder="Please enter your organization name"
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Industry Vertical</InputLabel>
            <Select
              name="industryVertical"
              value={userDetails.industryVertical}
              onChange={handleChange}
            >
              <MenuItem value="technology">Technology</MenuItem>
              <MenuItem value="finance">Finance</MenuItem>
              <MenuItem value="healthcare">Healthcare</MenuItem>
              <MenuItem value="advertising">Advertising</MenuItem>
              <MenuItem value="education">Education</MenuItem>              
              <MenuItem value="retail">Retail</MenuItem>
              {/* Add more industry options as needed */}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Title</InputLabel>
            <Select
              name="title"
              value={userDetails.title}
              onChange={handleChange}
            >
              <MenuItem value="ceo">CEO</MenuItem>
              <MenuItem value="cto">CTO</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="developer">Developer</MenuItem>
              {/* Add more title options as needed */}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Organization Revenue</InputLabel>
            <Select
              name="organizationRevenue"
              value={userDetails.organizationRevenue}
              onChange={handleChange}
            >
              <MenuItem value="<1M">Less than $1 million</MenuItem>
              <MenuItem value="1M-10M">$1 million - $10 million</MenuItem>
              <MenuItem value="10M-100M">$10 million - $100 million</MenuItem>
              <MenuItem value=">100M">More than $100 million</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 3 }}>
            <InfoIcon sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant="caption">
              Please avoid sharing sensitive data such as passwords or credit card info in this form. This is not a secure login or billing page to share that data.
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            sx={{ mt: 2 }}
          >
            Next
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default AIQuestUserDetails;
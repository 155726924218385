// src/components/features/AIQuest/IdeaGeneration/renderFunctions.js
import React from 'react';
import { Typography, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Rating, Box, Chip } from '@mui/material';
import { StyledPaper, StyledCard, StyledChip, colorPalette } from './utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { styled } from '@mui/material/styles';

const renderErrorMessage = (message) => (
    <Typography color="error">{message}</Typography>
  );


export const renderPainPoints = (data) => {
    console.log('Rendering pain points:', data);    
  if (!data || !data.pain_points || !Array.isArray(data.pain_points)) {
    return <Typography color="error">No pain points data available or data is in incorrect format.</Typography>;
  }
  
  return (
    <Grid container spacing={2}>
      {data.pain_points.map((point, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>{point.description}</Typography>
              <StyledChip label={point.category} color="primary" />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>Severity:</Typography>
                <Rating value={point.severity} readOnly max={5} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>Frequency:</Typography>
                <Rating value={point.frequency} readOnly max={5} />
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export const renderRootCauseAnalysis = (data) => {
    console.log('Root cause analysis data:', data);
    
    if (!data || !data.root_causes || !Array.isArray(data.root_causes)) {
      console.error('Invalid root cause analysis data:', data);
      return <Typography color="error">No root cause analysis data available or data is in incorrect format.</Typography>;
    }
  
    return (
      <Grid container spacing={2}>
        {data.root_causes.map((cause, index) => (
          <Grid item xs={12} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>{cause.pain_point || 'Unnamed Pain Point'}</Typography>
                <Typography variant="subtitle1">Analysis:</Typography>
                {Array.isArray(cause.analysis) ? cause.analysis.map((step, stepIndex) => (
                  <Typography key={stepIndex} paragraph>
                    <ArrowForwardIcon sx={{ color: colorPalette.primary, mr: 1 }} />
                    {step}
                  </Typography>
                )) : <Typography>No analysis steps available</Typography>}
                <Typography variant="subtitle1">Underlying Cause:</Typography>
                <Typography paragraph>{cause.underlying_cause || 'No underlying cause specified'}</Typography>
                <Typography variant="subtitle1">Connections:</Typography>
                {Array.isArray(cause.connections) ? cause.connections.map((connection, connIndex) => (
                  <Typography key={connIndex} paragraph>
                    <CheckCircleOutlineIcon sx={{ color: colorPalette.accent5, mr: 1 }} />
                    {connection}
                  </Typography>
                )) : <Typography>No connections specified</Typography>}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };

  export const renderIdeas = (data) => {
    console.log('Rendering ideas:', data);
    const ideasData = data?.idea_generation?.ideas || data?.ideas;
    
    if (!ideasData || !Array.isArray(ideasData)) {
      console.error('Invalid ideas data:', data);
      return <Typography color="error">No ideas data available or data is in incorrect format.</Typography>;
    }
    
    return (
      <Grid container spacing={2}>
        {ideasData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>{item.pain_point}</Typography>
                {Array.isArray(item.ideas) ? (
                  item.ideas.map((idea, ideaIndex) => (
                    <Typography key={ideaIndex} paragraph>
                      <LightbulbIcon sx={{ color: colorPalette.accent3, mr: 1 }} />
                      {idea}
                    </Typography>
                  ))
                ) : (
                  <Typography>
                    <LightbulbIcon sx={{ color: colorPalette.accent3, mr: 1 }} />
                    {typeof item.ideas === 'string' ? item.ideas : 'No idea description available'}
                  </Typography>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };


  
  export const renderSolutionMapping = (data) => {
    console.log('Rendering solution mapping:', data);
    if (!data || !data.solution_mapping || !Array.isArray(data.solution_mapping)) {
      return renderErrorMessage("No solution mapping data available or data is in incorrect format.");
    }
    
    return (
      <Grid container spacing={2}>
        {data.solution_mapping.map((solution, index) => (
          <Grid item xs={12} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>{solution.pain_point}</Typography>
                <Grid container spacing={2}>
                  {Array.isArray(solution.solutions) ? solution.solutions.map((sol, solIndex) => (
                    <Grid item xs={12} sm={6} md={4} key={solIndex}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="subtitle1">{sol.description}</Typography>
                          <StyledChip label={sol.type} color="primary" sx={{ mt: 1 }} />
                          <Typography variant="subtitle2" sx={{ mt: 2 }}>Addresses:</Typography>
                          {Array.isArray(sol.addresses) ? sol.addresses.map((addr, addrIndex) => (
                            <Typography key={addrIndex} paragraph>
                              <CheckCircleOutlineIcon sx={{ color: colorPalette.accent5, mr: 1 }} />
                              {addr}
                            </Typography>
                          )) : <Typography>No addresses specified</Typography>}
                        </CardContent>
                      </Card>
                    </Grid>
                  )) : <Typography>No solutions available</Typography>}
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  export const renderFeasibilityAssessment = (data) => {
    console.log('Rendering feasibility assessment:', data);
    if (!data || !data.feasibility_assessment || !Array.isArray(data.feasibility_assessment)) {
      return renderErrorMessage("No feasibility assessment data available or data is in incorrect format.");
    }
  
    return (
      <Grid container spacing={2}>
        {data.feasibility_assessment.map((assessment, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>{assessment.solution}</Typography>
                <Typography variant="subtitle2">Resources:</Typography>
                <Typography>Time: {assessment.resources?.time || 'Not specified'}</Typography>
                <Typography>Money: {assessment.resources?.money || 'Not specified'}</Typography>
                <Typography>Personnel: {assessment.resources?.personnel || 'Not specified'}</Typography>
                <Typography variant="subtitle2" sx={{ mt: 2 }}>Technical Feasibility:</Typography>
                <Typography paragraph>{assessment.technical_feasibility || 'Not specified'}</Typography>
                <Typography variant="subtitle2">Risks and Challenges:</Typography>
                {Array.isArray(assessment.risks_and_challenges) ? assessment.risks_and_challenges.map((risk, riskIndex) => (
                  <Typography key={riskIndex} paragraph>
                    <ErrorOutlineIcon sx={{ color: colorPalette.accent1, mr: 1 }} />
                    {risk}
                  </Typography>
                )) : <Typography>No risks and challenges specified</Typography>}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };
  

  export const renderImpactScoring = (data) => {
    console.log('Rendering impact scoring:', data);
    if (!data || !data.impact_scoring || !Array.isArray(data.impact_scoring)) {
      return renderErrorMessage("No impact scoring data available or data is in incorrect format.");
    }
    
    return (
      <Grid container spacing={2}>
        {data.impact_scoring.map((score, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>{score.solution}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>Pain Point Alleviation:</Typography>
                  <Rating value={score.pain_point_alleviation} readOnly max={5} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>Workflow Improvement:</Typography>
                  <Rating value={score.workflow_improvement} readOnly max={5} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>ROI:</Typography>
                  <Rating value={score.roi} readOnly max={5} />
                </Box>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>Total Score: {score.total_score}</Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };
  

  
  export const renderPrioritizationMatrix = (data) => {
    console.log('Rendering prioritization matrix:', data);
    if (!data || !Array.isArray(data)) {
      console.error('Invalid prioritization matrix data:', data);
      return <Typography color="error">No prioritization matrix data available or data is in incorrect format.</Typography>;
    }
  
    const categories = [
      { name: 'Quick Win', color: '#4caf50' },
      { name: 'Strategic Initiative', color: '#2196f3' },
      { name: 'Low Priority', color: '#ff9800' },
      { name: 'High Effort, High Impact', color: '#9c27b0' }
    ];
  
    return (
      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} key={category.name}>
            <StyledCard categoryColor={category.color}>
              <CardContent>
                <Typography variant="h6" align="center">{category.name}</Typography>
                <List>
                  {data.filter(item => item.category === category.name).map((item, index) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={item.solution}
                        secondary={
                          <Box mt={1}>
                            <StyledChip 
                              label={`Effort: ${item.effort_cost}`} 
                              size="small" 
                              color={item.effort_cost > 3 ? 'error' : 'success'}
                            />
                            <StyledChip 
                              label={`Impact: ${item.impact_benefit}`} 
                              size="small" 
                              color={item.impact_benefit > 3 ? 'success' : 'warning'}
                            />
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  export const renderActionPlanning = (data) => {
    console.log('Rendering action planning:', data);
    const actionPlans = data.action_planning || [];
    
    if (!Array.isArray(actionPlans) || actionPlans.length === 0) {
      return <Typography color="error">No action planning data available or data is in incorrect format.</Typography>;
    }
    
    return (
      <Grid container spacing={3}>
        {actionPlans.map((plan, index) => (
          <Grid item xs={12} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom color={colorPalette.primary}>
                  {plan.solution}
                </Typography>
                <Typography variant="subtitle1" color={colorPalette.secondary}>Timeline: {plan.timeline}</Typography>
                <Box mt={2}>
                  <Typography variant="subtitle2">Objectives:</Typography>
                  <List>
                    {plan.objectives.map((objective, objIndex) => (
                      <ListItem key={objIndex}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon sx={{ color: colorPalette.accent5 }} />
                        </ListItemIcon>
                        <ListItemText primary={objective} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle2">Responsibilities:</Typography>
                  <List>
                    {plan.responsibilities.map((responsibility, respIndex) => (
                      <ListItem key={respIndex}>
                        <ListItemIcon>
                          <AssignmentIcon sx={{ color: colorPalette.accent3 }} />
                        </ListItemIcon>
                        <ListItemText primary={responsibility} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle2">Success Metrics:</Typography>
                  <List>
                    {plan.success_metrics.map((metric, metricIndex) => (
                      <ListItem key={metricIndex}>
                        <ListItemIcon>
                          <TrendingUpIcon sx={{ color: colorPalette.accent2 }} />
                        </ListItemIcon>
                        <ListItemText primary={metric} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };


// // src/components/features/AIQuest/IdeaGeneration/renderFunctions.js
// import React from 'react';
// import { Typography, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Rating, Box, Chip } from '@mui/material';
// import { StyledPaper, StyledCard, StyledChip, colorPalette } from './utils';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import LightbulbIcon from '@mui/icons-material/Lightbulb';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import { styled } from '@mui/material/styles';

// const renderErrorMessage = (message) => (
//     <Typography color="error">{message}</Typography>
//   );

// export const renderPainPoints = (data, wrapWithLikeDislike = (content) => content) => {
//     if (!data || !data.pain_points || !Array.isArray(data.pain_points)) {
//       return <Typography color="error">No pain points data available or data is in incorrect format.</Typography>;
//     }
    
//     return (
//       <Grid container spacing={2}>
//         {data.pain_points.map((point, index) => wrapWithLikeDislike(
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>{point.description}</Typography>
//                 <StyledChip label={point.category} color="primary" />
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
//                   <Typography variant="body2" sx={{ mr: 1 }}>Severity:</Typography>
//                   <Rating value={point.severity} readOnly max={5} />
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <Typography variant="body2" sx={{ mr: 1 }}>Frequency:</Typography>
//                   <Rating value={point.frequency} readOnly max={5} />
//                 </Box>
//               </CardContent>
//             </StyledCard>
//           </Grid>,
//           index
//         ))}
//       </Grid>
//     );
//   };
  

// export const renderRootCauseAnalysis = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Root cause analysis data:', data);
    
//     if (!data || !data.root_causes || !Array.isArray(data.root_causes)) {
//       console.error('Invalid root cause analysis data:', data);
//       return <Typography color="error">No root cause analysis data available or data is in incorrect format.</Typography>;
//     }
  
//     return (
//       <Grid container spacing={2}>
//         {data.root_causes.map((cause, index) => (
//           <Grid item xs={12} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>{cause.pain_point || 'Unnamed Pain Point'}</Typography>
//                 <Typography variant="subtitle1">Analysis:</Typography>
//                 {Array.isArray(cause.analysis) ? cause.analysis.map((step, stepIndex) => (
//                   <Typography key={stepIndex} paragraph>
//                     <ArrowForwardIcon sx={{ color: colorPalette.primary, mr: 1 }} />
//                     {step}
//                   </Typography>
//                 )) : <Typography>No analysis steps available</Typography>}
//                 <Typography variant="subtitle1">Underlying Cause:</Typography>
//                 <Typography paragraph>{cause.underlying_cause || 'No underlying cause specified'}</Typography>
//                 <Typography variant="subtitle1">Connections:</Typography>
//                 {Array.isArray(cause.connections) ? cause.connections.map((connection, connIndex) => (
//                   <Typography key={connIndex} paragraph>
//                     <CheckCircleOutlineIcon sx={{ color: colorPalette.accent5, mr: 1 }} />
//                     {connection}
//                   </Typography>
//                 )) : <Typography>No connections specified</Typography>}
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };

//   export const renderIdeas = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Rendering ideas:', data);
//     const ideasData = data.idea_generation?.ideas || data.ideas;
    
//     if (!ideasData || !Array.isArray(ideasData)) {
//       console.error('Invalid ideas data:', data);
//       return <Typography color="error">No ideas data available or data is in incorrect format.</Typography>;
//     }
    
//     return (
//       <Grid container spacing={2}>
//         {ideasData.map((item, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>{item.pain_point}</Typography>
//                 {Array.isArray(item.ideas) ? (
//                   item.ideas.map((idea, ideaIndex) => (
//                     <Typography key={ideaIndex} paragraph>
//                       <LightbulbIcon sx={{ color: colorPalette.accent3, mr: 1 }} />
//                       {idea}
//                     </Typography>
//                   ))
//                 ) : (
//                   <Typography>
//                     <LightbulbIcon sx={{ color: colorPalette.accent3, mr: 1 }} />
//                     {typeof item.ideas === 'string' ? item.ideas : 'No idea description available'}
//                   </Typography>
//                 )}
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };


  
//   export const renderSolutionMapping = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Rendering solution mapping:', data);
//     if (!data || !data.solution_mapping || !Array.isArray(data.solution_mapping)) {
//       return renderErrorMessage("No solution mapping data available or data is in incorrect format.");
//     }
    
//     return (
//       <Grid container spacing={2}>
//         {data.solution_mapping.map((solution, index) => (
//           <Grid item xs={12} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>{solution.pain_point}</Typography>
//                 <Grid container spacing={2}>
//                   {Array.isArray(solution.solutions) ? solution.solutions.map((sol, solIndex) => (
//                     <Grid item xs={12} sm={6} md={4} key={solIndex}>
//                       <Card variant="outlined">
//                         <CardContent>
//                           <Typography variant="subtitle1">{sol.description}</Typography>
//                           <StyledChip label={sol.type} color="primary" sx={{ mt: 1 }} />
//                           <Typography variant="subtitle2" sx={{ mt: 2 }}>Addresses:</Typography>
//                           {Array.isArray(sol.addresses) ? sol.addresses.map((addr, addrIndex) => (
//                             <Typography key={addrIndex} paragraph>
//                               <CheckCircleOutlineIcon sx={{ color: colorPalette.accent5, mr: 1 }} />
//                               {addr}
//                             </Typography>
//                           )) : <Typography>No addresses specified</Typography>}
//                         </CardContent>
//                       </Card>
//                     </Grid>
//                   )) : <Typography>No solutions available</Typography>}
//                 </Grid>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };
  
//   export const renderFeasibilityAssessment = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Rendering feasibility assessment:', data);
//     if (!data || !data.feasibility_assessment || !Array.isArray(data.feasibility_assessment)) {
//       return renderErrorMessage("No feasibility assessment data available or data is in incorrect format.");
//     }
  
//     return (
//       <Grid container spacing={2}>
//         {data.feasibility_assessment.map((assessment, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>{assessment.solution}</Typography>
//                 <Typography variant="subtitle2">Resources:</Typography>
//                 <Typography>Time: {assessment.resources?.time || 'Not specified'}</Typography>
//                 <Typography>Money: {assessment.resources?.money || 'Not specified'}</Typography>
//                 <Typography>Personnel: {assessment.resources?.personnel || 'Not specified'}</Typography>
//                 <Typography variant="subtitle2" sx={{ mt: 2 }}>Technical Feasibility:</Typography>
//                 <Typography paragraph>{assessment.technical_feasibility || 'Not specified'}</Typography>
//                 <Typography variant="subtitle2">Risks and Challenges:</Typography>
//                 {Array.isArray(assessment.risks_and_challenges) ? assessment.risks_and_challenges.map((risk, riskIndex) => (
//                   <Typography key={riskIndex} paragraph>
//                     <ErrorOutlineIcon sx={{ color: colorPalette.accent1, mr: 1 }} />
//                     {risk}
//                   </Typography>
//                 )) : <Typography>No risks and challenges specified</Typography>}
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };
  

//   export const renderImpactScoring = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Rendering impact scoring:', data);
//     if (!data || !data.impact_scoring || !Array.isArray(data.impact_scoring)) {
//       return renderErrorMessage("No impact scoring data available or data is in incorrect format.");
//     }
    
//     return (
//       <Grid container spacing={2}>
//         {data.impact_scoring.map((score, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>{score.solution}</Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <Typography variant="body2" sx={{ mr: 1 }}>Pain Point Alleviation:</Typography>
//                   <Rating value={score.pain_point_alleviation} readOnly max={5} />
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <Typography variant="body2" sx={{ mr: 1 }}>Workflow Improvement:</Typography>
//                   <Rating value={score.workflow_improvement} readOnly max={5} />
//                 </Box>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <Typography variant="body2" sx={{ mr: 1 }}>ROI:</Typography>
//                   <Rating value={score.roi} readOnly max={5} />
//                 </Box>
//                 <Typography variant="subtitle1" sx={{ mt: 2 }}>Total Score: {score.total_score}</Typography>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };
  

  
//   export const renderPrioritizationMatrix = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Rendering prioritization matrix:', data);
//     if (!data || !Array.isArray(data)) {
//       console.error('Invalid prioritization matrix data:', data);
//       return <Typography color="error">No prioritization matrix data available or data is in incorrect format.</Typography>;
//     }
  
//     const categories = [
//       { name: 'Quick Win', color: '#4caf50' },
//       { name: 'Strategic Initiative', color: '#2196f3' },
//       { name: 'Low Priority', color: '#ff9800' },
//       { name: 'High Effort, High Impact', color: '#9c27b0' }
//     ];
  
//     return (
//       <Grid container spacing={3}>
//         {categories.map((category) => (
//           <Grid item xs={12} sm={6} key={category.name}>
//             <StyledCard categoryColor={category.color}>
//               <CardContent>
//                 <Typography variant="h6" align="center">{category.name}</Typography>
//                 <List>
//                   {data.filter(item => item.category === category.name).map((item, index) => (
//                     <ListItem key={index} alignItems="flex-start">
//                       <ListItemText
//                         primary={item.solution}
//                         secondary={
//                           <Box mt={1}>
//                             <StyledChip 
//                               label={`Effort: ${item.effort_cost}`} 
//                               size="small" 
//                               color={item.effort_cost > 3 ? 'error' : 'success'}
//                             />
//                             <StyledChip 
//                               label={`Impact: ${item.impact_benefit}`} 
//                               size="small" 
//                               color={item.impact_benefit > 3 ? 'success' : 'warning'}
//                             />
//                           </Box>
//                         }
//                       />
//                     </ListItem>
//                   ))}
//                 </List>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };
  
//   export const renderActionPlanning = (data, wrapWithLikeDislike = (content) => content) => {
//     console.log('Rendering action planning:', data);
//     const actionPlans = data.action_planning || [];
    
//     if (!Array.isArray(actionPlans) || actionPlans.length === 0) {
//       return <Typography color="error">No action planning data available or data is in incorrect format.</Typography>;
//     }
    
//     return (
//       <Grid container spacing={3}>
//         {actionPlans.map((plan, index) => (
//           <Grid item xs={12} key={index}>
//             <StyledCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom color={colorPalette.primary}>
//                   {plan.solution}
//                 </Typography>
//                 <Typography variant="subtitle1" color={colorPalette.secondary}>Timeline: {plan.timeline}</Typography>
//                 <Box mt={2}>
//                   <Typography variant="subtitle2">Objectives:</Typography>
//                   <List>
//                     {plan.objectives.map((objective, objIndex) => (
//                       <ListItem key={objIndex}>
//                         <ListItemIcon>
//                           <CheckCircleOutlineIcon sx={{ color: colorPalette.accent5 }} />
//                         </ListItemIcon>
//                         <ListItemText primary={objective} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Box>
//                 <Box mt={2}>
//                   <Typography variant="subtitle2">Responsibilities:</Typography>
//                   <List>
//                     {plan.responsibilities.map((responsibility, respIndex) => (
//                       <ListItem key={respIndex}>
//                         <ListItemIcon>
//                           <AssignmentIcon sx={{ color: colorPalette.accent3 }} />
//                         </ListItemIcon>
//                         <ListItemText primary={responsibility} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Box>
//                 <Box mt={2}>
//                   <Typography variant="subtitle2">Success Metrics:</Typography>
//                   <List>
//                     {plan.success_metrics.map((metric, metricIndex) => (
//                       <ListItem key={metricIndex}>
//                         <ListItemIcon>
//                           <TrendingUpIcon sx={{ color: colorPalette.accent2 }} />
//                         </ListItemIcon>
//                         <ListItemText primary={metric} />
//                       </ListItem>
//                     ))}
//                   </List>
//                 </Box>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}
//       </Grid>
//     );
//   };
// src/contexts/AuthContext.js
import React, { createContext, useContext, useState,  useEffect } from 'react';
import axios from 'axios';
import api from '../services/api';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);
  // const login = async (username, password) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('username', username);
  //     formData.append('password', password);
  
  //     const response = await api.post('/token', formData, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       }
  //     });
  
  //     console.log('Login response:', response.data);
      
  //     const updateProfile = async (profileData) => {
  //       try {
  //         const response = await api.put('/users/me', profileData, {
  //           headers: { Authorization: `Bearer ${token}` }
  //         });
  //         setUser(response.data);
  //       } catch (error) {
  //         console.error('Failed to update profile:', error);
  //         throw error;
  //       }
  //     };

  //     const { access_token } = response.data;
  //     setToken(access_token);
  //     localStorage.setItem('token', access_token);
      
  //     console.log('Token set, fetching user...');
  //     await fetchUser();
      
  //     console.log('User fetched, user state:', user);
      
  //     return true; // Indicate successful login
  //   } catch (error) {
  //     console.error('Login failed:', error);
  //     throw error;
  //   }
  // };

  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [token]);

  // const login = async (username, password) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('username', username);
  //     formData.append('password', password);
  
  //     const response = await api.post('/token', formData, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       }
  //     });
  
  //     const { access_token } = response.data;
  //     setToken(access_token);
  //     localStorage.setItem('token', access_token);
      
  //     await fetchUser();
      
  //     return true;
  //   } catch (error) {
  //     console.error('Login failed:', error);
  //     throw error;
  //   }
  // };
  const login = async (username, password) => {
    try {
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);
  
      const response = await api.post('/token', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      const { access_token } = response.data;
      setToken(access_token);
      localStorage.setItem('token', access_token);
      
      await fetchUser();
      
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const fetchUser = async () => {
    if (!token) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const response = await api.get('/users/me');
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user:', error);
      if (error.response && error.response.status === 401) {
        logout();
      }
    } finally {
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const response = await api.post('/refresh-token');
      const { access_token } = response.data;
      setToken(access_token);
      localStorage.setItem('token', access_token);
      return access_token;
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout();
    }
  };

  const register = async (username, email, password, fullName) => {
    try {
      await axios.post('/register', { username, email, password, full_name: fullName });
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
  };

// src/contexts/AuthContext.js
// const fetchUser = async () => {
//     if (!token) return;
//     try {
//       const response = await api.get('/users/me', {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       console.log('Fetched user data:', response.data);
//       setUser(response.data);
//     } catch (error) {
//       console.error('Failed to fetch user:', error);
//       logout();
//     }
//   };
  
  const updateProfile = async (profileData) => {
    try {
      const response = await api.put('/users/me', profileData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to update profile:', error);
      throw error;
    }
  };
  
  return (
    <AuthContext.Provider value={{ user, token, login, logout, fetchUser, register, updateProfile, refreshToken, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
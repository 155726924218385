// src/components/layout/Layout.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, TextField, MenuItem, Select, FormControl, InputLabel, Button, CircularProgress } from '@mui/material';
import { Outlet, Link, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudIcon from '@mui/icons-material/Cloud';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import ChatInput from '../common/ChatInput';
import SmartSearchPage from '../features/SmartSearch/SmartSearchPage';
import DataAnalystPage from '../features/DataAnalyst/DataAnalystPage';
import UserMenu from '../common/UserMenu';
import Dialog from '../common/Dialog';
import { useAppContext } from '../../contexts/AppContext';
import { useAuth } from '../../contexts/AuthContext';
import PersonIcon from '@mui/icons-material/Person';
import AIQuestSidebar from '../features/AIQuest/AIQuestSidebar';
import AIQuestLanding from '../features/AIQuest/AIQuestLanding';
import { FormControlLabel, Switch } from '@mui/material';

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState('Models');
  const [modelProvider, setModelProvider] = useState('ollama');
  const [profileName, setProfileName] = useState('');
  const [profileEmail, setProfileEmail] = useState('');
  const { user, updateProfile } = useAuth();  
  
  const [useRagFusion, setUseRagFusion] = useState(false);
  
  const { 
    chatSessions, 
    currentChatIndex, 
    selectedDatabase, 
    setSelectedDatabase,
    handleSendMessage,
    llmConfig,
    setLlmConfig,
    sessionId,
    setSessionId,
    isLoading,
    error
  } = useAppContext();

  const location = useLocation();
  const navigate = useNavigate();
  const [isAIQuestRoute, setIsAIQuestRoute] = useState(false);

  useEffect(() => {
    setIsAIQuestRoute(location.pathname.startsWith('/aiquest'));
  }, [location]);

  useEffect(() => {
    const currentChat = chatSessions[currentChatIndex];
    if (currentChat && currentChat.feature && location.pathname === '/') {
      navigate(`/${currentChat.feature}`);
    }
  }, [currentChatIndex, chatSessions, location.pathname, navigate]);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const handleOpenSettings = () => setSettingsOpen(true);
  const handleCloseSettings = () => setSettingsOpen(false);

  const handleProfileUpdate = async () => {
    try {
      await updateProfile({ full_name: profileName, email: profileEmail });
      // Show success message
    } catch (error) {
      console.error('Failed to update profile:', error);
      // Show error message
    }
  };

  const handleBackNavigation = () => {
    if (location.pathname === '/smart-search' && selectedDatabase) {
      setSelectedDatabase(null);
    } else {
      navigate('/');
    }
  };

  const canGoBack = location.pathname !== '/';

  const showChatInput = (location.pathname === '/smart-search' || location.pathname === '/data-analyst') && selectedDatabase;

  const handleModelProviderChange = (event) => {
    const newProvider = event.target.value;
    setModelProvider(newProvider);
    setLlmConfig({
      ...llmConfig,
      provider: newProvider,
      model: getDefaultModel(newProvider),
      endpoint: getDefaultEndpoint(newProvider)
    });
    // Reset the session when changing the model
    setSessionId(null);
  };

  const getDefaultModel = (provider) => {
    switch (provider) {
      case 'openai': return 'gpt-4o-mini';
      case 'groq': return 'llama-3.1-70b-versatile';
      case 'ollama': return 'llama3.1';
      case 'claude': return 'claude-2';
      default: return '';
    }
  };

  const getDefaultEndpoint = (provider) => {
    switch (provider) {
      case 'ollama': return 'http://localhost:11434';
      default: return '';
    }
  };

  const handleSaveModelConfig = () => {
    // Here you would typically save the configuration to your backend or local storage
    console.log('Saving model configuration:', { ...llmConfig, useRagFusion });
    // Show a success message to the user
    // Close the settings dialog if needed
  };

  const settingsContent = (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <List>
          {[
            { name: 'Connections', icon: <CloudIcon /> },
            { name: 'Models', icon: <ModelTrainingIcon /> },
            { name: 'Profile', icon: <PersonIcon /> },
          ].map((item) => (
            <ListItem 
              button 
              key={item.name} 
              selected={selectedSetting === item.name}
              onClick={() => setSelectedSetting(item.name)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9}>
        <Box sx={{ p: 2 }}>
          {selectedSetting === 'Models' && (
            <>
          <Typography variant="h6" gutterBottom>Model Configuration</Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Model Provider</InputLabel>
            <Select
              value={modelProvider}
              onChange={handleModelProviderChange}
              label="Model Provider"
            >
              <MenuItem value="openai">OpenAI</MenuItem>
              <MenuItem value="groq">Groq</MenuItem>
              <MenuItem value="ollama">Ollama</MenuItem>
              <MenuItem value="claude">Claude</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Vector Store Type</InputLabel>
          <Select
            value={llmConfig.vector_store_type}
            onChange={(e) => setLlmConfig({ ...llmConfig, vector_store_type: e.target.value })}
            label="Vector Store Type"
          >
            <MenuItem value="chroma">Chroma</MenuItem>
            <MenuItem value="pinecone">Pinecone</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={useRagFusion}
              onChange={(e) => setUseRagFusion(e.target.checked)}
            />
          }
          label="Use RAG Fusion"
        />
          <TextField
            fullWidth
            label="Model"
            value={llmConfig.model}
            onChange={(e) => setLlmConfig({ ...llmConfig, model: e.target.value })}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="API Key (if applicable)"
            value={llmConfig.api_key || ''}
            onChange={(e) => setLlmConfig({ ...llmConfig, api_key: e.target.value })}
            variant="outlined"
            sx={{ mb: 2 }}
            type="password"
          />
          <TextField
            fullWidth
            label="Endpoint (if applicable)"
            value={llmConfig.endpoint || ''}
            onChange={(e) => setLlmConfig({ ...llmConfig, endpoint: e.target.value })}
            variant="outlined"
            sx={{ mb: 2 }}
          />
            <Button variant="contained" onClick={handleSaveModelConfig}>
            Save Configuration
            </Button>          
          </>
        )}
        {selectedSetting === 'Profile' && (
          <>
            <Typography variant="h6" gutterBottom>User Profile</Typography>
            <TextField
              fullWidth
              label="Full Name"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Email"
              value={profileEmail}
              onChange={(e) => setProfileEmail(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={handleProfileUpdate}>
              Update Profile
            </Button>
          </>
        )}
      </Box>
    </Grid>
  </Grid>
);
 
return (
  <Box sx={{ display: 'flex', height: '100vh' }}>
    <Grid container sx={{ height: '100%' }}>
      {sidebarOpen && (
        <Grid item xs={12} sm={4} md={3} lg={2} sx={{ height: '100%' }}>
          {isAIQuestRoute ? <AIQuestSidebar /> : <Sidebar />}
        </Grid>
      )}
      <Grid item xs={12} sm={sidebarOpen ? 8 : 12} md={sidebarOpen ? 9 : 12} lg={sidebarOpen ? 10 : 12} sx={{ height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton color="inherit" size="small" onClick={toggleSidebar}>
                <MenuIcon fontSize="small" />
              </IconButton>
              {canGoBack && (
                <IconButton color="inherit" size="small" onClick={handleBackNavigation} sx={{ ml: 1 }}>
                  <ArrowBackIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton color="inherit" size="small" onClick={handleOpenSettings} sx={{ ml: 1 }}>
                <SettingsIcon fontSize="small" />
              </IconButton>
              {!isAIQuestRoute && (
                <FormControl sx={{ ml: 1, minWidth: 120 }}>
                  <Select
                    value={modelProvider}
                    onChange={handleModelProviderChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    sx={{
                      color: '#C8326B',
                      '& .MuiSelect-select': {
                        paddingY: '2px',
                        paddingX: '8px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#C8326B',
                      },
                    }}
                  >
                    <MenuItem value="openai">OpenAI</MenuItem>
                    <MenuItem value="groq">Groq</MenuItem>
                    <MenuItem value="ollama">Ollama</MenuItem>
                    <MenuItem value="claude">Claude</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
            <UserMenu onOpenSettings={handleOpenSettings} />
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/data-analyst" element={<DataAnalystPage />} />
              <Route path="/smart-search/*" element={<SmartSearchPage />} />
              <Route path="/aiquest-landing" element={<AIQuestLanding />} />
            </Routes>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
              <Outlet />
            </Box>
            {!isAIQuestRoute && (
              <Link to="/aiquest">
                <Button>Take AIQuest Survey</Button>
              </Link>
            )}
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
          </Box>
          {showChatInput && (
            <Box sx={{ flexShrink: 0 }}>
              <ChatInput onSendMessage={handleSendMessage} />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
    <Dialog
      open={settingsOpen}
      onClose={handleCloseSettings}
      title="Settings"
      content={settingsContent}
      maxWidth="md"
    />
  </Box>
);
}

export default Layout;
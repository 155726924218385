// src/components/features/SmartSearch/SmartSearchPage.js
import React, { useState, useEffect } from 'react';
import { Box,Typography, FormControlLabel, Switch } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import DatabaseSelection from './DatabaseSelection';
import ChatWindow from './ChatWindow';
import DatabaseInfo from './DatabaseInfo';
import InstructionsPopup from './InstructionsPopup';
import UploadDialog from './UploadDialog';
import { CircularProgress } from '@mui/material';

function SmartSearchPage() {
  const { selectedDatabase, setSelectedDatabase, chatSessions, currentChatIndex, isLoading, error } = useAppContext();
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [instructionsAnchorEl, setInstructionsAnchorEl] = useState(null);
  const location = useLocation();
  const { useRagFusion, setUseRagFusion } = useAppContext();

  useEffect(() => {
    if (location.state?.loadChat) {
      const currentChat = chatSessions[currentChatIndex];
      if (currentChat && currentChat.messages.length > 0) {
        // Assuming the first message contains the database information
        const firstMessage = currentChat.messages[0];
        if (firstMessage.database) {
          setSelectedDatabase({ title: firstMessage.database });
        }
      }
    }
  }, [location.state, chatSessions, currentChatIndex, setSelectedDatabase]);

  const handleUploadClick = () => setUploaderOpen(true);
  const handleUploaderClose = () => setUploaderOpen(false);
  const handleInfoClick = () => setInfoOpen(true);
  const handleInfoClose = () => setInfoOpen(false);
  const handleInstructionsClick = (event) => setInstructionsAnchorEl(event.currentTarget);
  const handleInstructionsClose = () => setInstructionsAnchorEl(null);

  const handleDatabaseSelect = (database) => {
    setSelectedDatabase(database);
  };

  return (
    <Box sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column' }}>
    {!selectedDatabase ? (
      <DatabaseSelection onDatabaseSelect={handleDatabaseSelect} />
    ) : (
      <>
        <DatabaseInfo 
          database={selectedDatabase}
          onUploadClick={handleUploadClick}
          onInfoClick={handleInfoClick}
          onInstructionsClick={handleInstructionsClick}
          onDatabaseSelect={handleDatabaseSelect}
        />
        <ChatWindow />
        {isLoading && <CircularProgress sx={{ alignSelf: 'center', mt: 2 }} />}
        {error && <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>}
      </>
    )}
          {/* <FormControlLabel
        control={
          <Switch
            checked={useRagFusion}
            onChange={(e) => setUseRagFusion(e.target.checked)}
          />
        }
        label="Use RAG Fusion"
      /> */}
      <UploadDialog
        open={uploaderOpen}
        onClose={handleUploaderClose}
        database={selectedDatabase}
      />
      <InstructionsPopup
        anchorEl={instructionsAnchorEl}
        onClose={handleInstructionsClose}
      />
    </Box>
  );
}

export default SmartSearchPage;
// src/components/features/AIQuest/IdeaGeneration/BusinessContext.js

import React, { useMemo } from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, Grid, Card, CardContent, Avatar, CircularProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ComputerIcon from '@mui/icons-material/Computer';
import AssessmentIcon from '@mui/icons-material/Assessment';

function BusinessContext({ businessContext, painPoints, mcqAnswers, mcqQuestions, aiquestInsights, organization, interviewee }) {
  const contextSections = [
    { title: 'End User Profile', icon: <PersonIcon />, key: 'end_user_profile' },
    { title: 'KPIs', icon: <TrendingUpIcon />, key: 'kpis' },
    { title: 'Organizational Context', icon: <BusinessIcon />, key: 'organizational_context' },
    { title: 'Current Processes', icon: <SettingsIcon />, key: 'current_processes' },
    { title: 'Technology Stack', icon: <ComputerIcon />, key: 'technology_stack' },
  ];

  console.log("Business Context:", businessContext);
  console.log("AIQuest Insgts:", aiquestInsights);

  const actualBusinessContext = businessContext?.business_context || {};

  const getAnswerText = (question, answerId) => {
    if (!answerId) return 'Not answered';
    const selectedOption = question.options.find(option => option.id === answerId);
    return selectedOption ? selectedOption.text : 'Answer not found';
  };


  const renderAIQuestSummary = useMemo(() => {
    console.log("Rendering AIQuest Summary with data:", aiquestInsights);

    if (aiquestInsights === null) {
      return <CircularProgress />;
    }
    if (aiquestInsights === undefined) {
      return <Typography>AIQuest insights not available. Please complete the AIQuest survey.</Typography>;
    }
    if (!aiquestInsights.scores) {
      return <Typography>Invalid AIQuest insights data</Typography>;
    }

    return (
      <>
        <Typography variant="subtitle1">
          Overall AI Readiness Score: {aiquestInsights.scores.overall_score.toFixed(2)}
        </Typography>
        <Typography variant="subtitle2">Key Findings:</Typography>
        <List>
          {aiquestInsights.recommendations.key_findings.map((finding, index) => (
            <ListItem key={index}>
              <ListItemText primary={finding} />
            </ListItem>
          ))}
        </List>
      </>
    );
  }, [aiquestInsights]);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h4" gutterBottom color="primary">Business Context</Typography>
      {organization && (
        <Box mb={2}>
          <Typography variant="subtitle1">Organization: {organization.name}</Typography>
        </Box>
      )}
      
      {interviewee && (
        <Box mb={2}>
          <Typography variant="subtitle1">Interviewee: {interviewee.name}</Typography>
        </Box>
      )}
      <Box mb={3}>
        <Typography variant="subtitle1" gutterBottom>Pain Points:</Typography>
        <Typography paragraph>{painPoints || 'No pain points provided'}</Typography>
      </Box>
      <Grid container spacing={3}>
        {contextSections.map((section) => (
          <Grid item xs={12} sm={6} md={4} key={section.key}>
            <Card>
              <CardContent>
                <Avatar sx={{ bgcolor: 'primary.main', mb: 2 }}>{section.icon}</Avatar>
                <Typography variant="h6" gutterBottom>{section.title}</Typography>
                <Typography variant="body2">
                  {actualBusinessContext[section.key]
                    ? Array.isArray(actualBusinessContext[section.key])
                      ? actualBusinessContext[section.key].join(', ')
                      : actualBusinessContext[section.key]
                    : 'No information available'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>AIQuest Summary:</Typography>
        <Card>
          <CardContent>
            <Avatar sx={{ bgcolor: 'secondary.main', mb: 2 }}>
              <AssessmentIcon />
            </Avatar>
            {renderAIQuestSummary}
          </CardContent>
        </Card>
      </Box>
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>Additional Information:</Typography>
        <List>
          {mcqQuestions.map((question, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={question.text}
                secondary={`Answer: ${getAnswerText(question, mcqAnswers[question.id])}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  );
}

export default React.memo(BusinessContext);

// checkpoint
// // src/components/features/AIQuest/IdeaGeneration/MCQDialog.js

// import React, { useEffect } from 'react';
// import {
//   Dialog, DialogTitle, DialogContent, DialogActions,
//   Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Typography
// } from '@mui/material';

// function MCQDialog({ open, onClose, questions, answers, setAnswers, onSubmit, loading }) {
//   useEffect(() => {
//     console.log('MCQDialog questions:', questions);  // Debug log
//   }, [questions]);

//   const handleAnswer = (questionId, value) => {
//     setAnswers({ ...answers, [questionId]: value });
//   };

//   // Ensure questions is always an array
//   const safeQuestions = Array.isArray(questions) ? questions : [];

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Additional Questions</DialogTitle>
//       <DialogContent>
//         {safeQuestions.length === 0 ? (
//           <Typography color="error">No questions available. Please try again.</Typography>
//         ) : (
//           safeQuestions.map((question) => (
//             <FormControl component="fieldset" key={question.id} sx={{ mb: 2 }}>
//               <FormLabel component="legend">{question.text}</FormLabel>
//               <RadioGroup
//                 value={answers[question.id] || ''}
//                 onChange={(e) => handleAnswer(question.id, e.target.value)}
//               >
//                 {question.options.map((option) => (
//                   <FormControlLabel
//                     key={option.id}
//                     value={option.id}
//                     control={<Radio />}
//                     label={option.text}
//                   />
//                 ))}
//               </RadioGroup>
//             </FormControl>
//           ))
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button
//           onClick={onSubmit}
//           disabled={safeQuestions.length === 0 || Object.keys(answers).length !== safeQuestions.length || loading}
//           variant="contained"
//           color="primary"
//         >
//           {loading ? <CircularProgress size={24} /> : 'Submit Answers'}
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }

// export default MCQDialog;

import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Typography
} from '@mui/material';

function MCQDialog({ open, onClose, questions, answers, setAnswers, onSubmit, loading }) {
  const handleAnswer = (questionId, value) => {
    setAnswers(prev => ({ ...prev, [questionId]: value }));
  };

  // Ensure questions is always an array
  const safeQuestions = Array.isArray(questions) ? questions : [];

  const isAnyQuestionAnswered = Object.keys(answers).length > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Additional Questions</DialogTitle>
      <DialogContent>
        {safeQuestions.length === 0 ? (
          <Typography color="error">No questions available. Please try again.</Typography>
        ) : (
          safeQuestions.map((question) => (
            <FormControl component="fieldset" key={question.id} sx={{ mb: 2 }}>
              <FormLabel component="legend">{question.text}</FormLabel>
              <RadioGroup
                value={answers[question.id] || ''}
                onChange={(e) => handleAnswer(question.id, e.target.value)}
              >
                {question.options.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={<Radio />}
                    label={option.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={onSubmit}
          disabled={!isAnyQuestionAnswered || loading}
          variant="contained"
          color="primary"
        >
          {loading ? <CircularProgress size={24} /> : 'Submit Answers'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MCQDialog;
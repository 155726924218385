// src/components/layout/MainContent.js
import React from 'react';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function MainContent() {
  const navigate = useNavigate();

  const capabilities = [
    {
      title: 'Smart Search',
      description: 'Use advanced AI to find specific information from documents',
      action: () => navigate('/smart-search'),
    },
    // {
    //   title: 'Data Taxonomy Builder',
    //   description: 'Your AI assistant to solve your data taxonomy nightmares',
    //   action: () => {},
    // },
    {
      title: 'Your Data Analyst',
      description: 'Your personal data and BI analyst',
      action: () => navigate('/data-analyst'),
    },
    // {
    //   title: 'Synthetic Data Generator',
    //   description: 'Your data privacy and security officer',
    //   action: () => {},
    // },
    {
      title: 'AIQuest',
      description: 'Assess your organization\'s AI readiness and strategy',
      action: () => navigate('/aiquest'),
    },
  ];

  return (
    <Box sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h2" sx={{ color: '#4A6572', fontWeight: 'bold' }}>
          Explore our WISSPs
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#4A6572' }}>
          Get smarter and simplify your everyday task with our WISSPS
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {capabilities.map((capability, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper 
              sx={{ 
                p: 3, 
                height: '100%', 
                bgcolor: '#FFF1F1', 
                display: 'flex', 
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={capability.action}
            >
              <Typography variant="h6" sx={{ mb: 2, color: '#4A6572' }}>
                {capability.title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, color: '#4A6572', flexGrow: 1 }}>
                {capability.description}
              </Typography>
              <Button color="primary" sx={{ textTransform: 'none', alignSelf: 'flex-start' }}>
                Learn more
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default MainContent;
// src/components/features/SmartSearch/UploadDialog.js
import React, { useState } from 'react';
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, Snackbar } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from '../../../services/api';

function UploadDialog({ open, onClose, database }) {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError('');
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
    setError('');
  };

  const handleUpload = async () => {
    if ((file || url) && database) {
      try {
        const formData = new FormData();
        if (file) formData.append('file', file);
        if (url) formData.append('url', url);
        formData.append('database', database.title);

        const result = await uploadFile(formData);
        setSuccessMessage(result.message);
        setFile(null);
        setUrl('');
        setError('');
      } catch (error) {
        console.error('Error uploading:', error);
        setError(error.message || 'An error occurred during upload');
      }
    } else {
      setError('Please provide a file or URL and select a database');
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Update Database</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              component="label"
              startIcon={<CloudUploadIcon />}
              sx={{ bgcolor: '#C8326B', '&:hover': { bgcolor: '#A62A59' } }}
            >
              Upload File
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".txt,.pdf"
              />
            </Button>
            {file && <Typography variant="body2">{file.name}</Typography>}
            <TextField
              fullWidth
              label="Enter URL (optional)"
              variant="outlined"
              value={url}
              onChange={handleUrlChange}
            />
            {error && <Typography color="error">{error}</Typography>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleUpload} disabled={!file && !url}>Upload</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={successMessage}
      />
    </>
  );
}

export default UploadDialog;
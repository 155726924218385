// src/data/useCasesData.js

export const useCasesData = {
    'Data Discovery': {
      Absent: [
        "Smart Data Finder: Basic AI tool to locate and categorize data across the organization",
        "Auto Data Profiler: Simple AI that provides quick summaries of datasets"
      ],
      Limited: [
        "Context-Aware Searcher: AI tool that understands the intent behind data queries",
        "Smart Data Classifier: AI that automatically tags and organizes datasets"
      ],
      Emergent: [
        "Data Relationship Mapper: AI that uncovers hidden connections between different datasets",
        "Smart Data Recommender: AI that suggests relevant datasets for specific analyses"
      ],
      Structured: [
        "Intelligent Data Catalog: AI-powered system that maintains an up-to-date inventory of all data assets",
        "Cross-Domain Insight Generator: AI that combines data from different areas for new insights"
      ],
      Integrated: [
        "Enterprise Knowledge Graph: AI-built system that maps all data and its relationships across the organization",
        "Cognitive Data Explorer: Advanced AI that understands and answers complex data questions in natural language"
      ]
    },
    'Pipelines': {
      Absent: [
        "Basic Data Connector: AI-assisted tool to link different data sources",
        "Smart Scheduler: AI that optimizes timing for data transfers"
      ],
      Limited: [
        "Intelligent ETL Assistant: AI-powered tool to help design data transformation processes",
        "Data Quality Guardian: AI that monitors and maintains data quality in pipelines"
      ],
      Emergent: [
        "Self-Optimizing Pipeline: AI that automatically improves data flow efficiency",
        "Adaptive Data Integrator: AI-powered system that adjusts to changes in data sources"
      ],
      Structured: [
        "Predictive Pipeline Maintenance: AI that foresees and prevents data pipeline issues",
        "Smart Data Orchestrator: AI system that manages complex data workflows automatically"
      ],
      Integrated: [
        "Autonomous Data Ecosystem: Self-managing AI system for end-to-end data operations",
        "Intelligent Data Fabric: AI-powered system that provides seamless data integration across the organization"
      ]
    },
    'Predictive Analytics': {
      Absent: [
        "Simple Trend Predictor: Basic AI model for forecasting based on historical data",
        "Entry-Level Anomaly Detector: AI tool to spot unusual patterns in data"
      ],
      Limited: [
        "Multi-Factor Forecaster: AI model that considers various factors for more accurate predictions",
        "Beginner's ML Model Builder: AI assistant that helps create simple machine learning models"
      ],
      Emergent: [
        "Auto Model Selector: AI that chooses the best predictive model for a given problem",
        "Scenario Simulator: AI tool that predicts outcomes for various business scenarios"
      ],
      Structured: [
        "Automated Machine Learning: AI that builds and optimizes predictive models with minimal human input",
        "Real-Time Decision Engine: AI system that makes instant predictions to guide business decisions"
      ],
      Integrated: [
        "Holistic Business Simulator: AI that models entire business ecosystems for strategic planning",
        "Continuous Learning Predictor: AI models that constantly update and improve themselves with new data"
      ]
    },
    'Visualizations': {
      Absent: [
        "Auto-Chart Picker: AI that suggests the best chart type for different data",
        "Interactive Data Story: Simple AI-powered tool for creating data narratives"
      ],
      Limited: [
        "Insight Highlighter: AI that automatically points out key insights in visualizations",
        "Smart Dashboard Creator: AI tool that builds customized dashboards based on user needs"
      ],
      Emergent: [
        "Natural Language Chart Generator: Create charts by describing them in plain language",
        "Context-Aware Visualizer: AI that adjusts visualizations based on the user's role and needs"
      ],
      Structured: [
        "AI-Powered Data Storyteller: Creates compelling narratives from complex data automatically",
        "Predictive Visual Analytics: AI that shows not just current trends, but likely future scenarios"
      ],
      Integrated: [
        "Immersive Data Experience: AI-driven VR/AR system for intuitive data exploration and analysis",
        "Adaptive Insight Communicator: AI that tailors data presentations to each user's preferences and learning style"
      ]
    }
  };
// src/components/features/AIQuest/IdeaGeneration/IdeaGenerationHistory.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function IdeaGenerationHistory({ history, loading, onSelectProcess, onDeleteProcess }) {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>Idea Generation History</Typography>
      {loading ? (
        <CircularProgress />
      ) : history.length === 0 ? (
        <Typography>No previous idea generation processes found.</Typography>
      ) : (
        <List>
          {history.map((process, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => onDeleteProcess(process.id)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`Organization: ${process.organization_name}, User: ${process.interviewee_name}`}
                secondary={`Pain Points: ${process.pain_points.substring(0, 50)}...`}
                onClick={() => onSelectProcess(process)}
                style={{ cursor: 'pointer' }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}

export default IdeaGenerationHistory;
// src/components/features/AIQuest/AIReadinessStages.js
import React from 'react';
import { Box, Typography, Paper, Tooltip } from '@mui/material';

const stages = [
  { name: 'Absent', color: '#c02f5a', textColor: '#ffffff', description: "AI initiatives are not present or recognized within the organization." },
  { name: 'Limited', color: '#f0d794', textColor: '#000000', description: "Some AI awareness exists, but initiatives are inconsistent and lack strategic direction." },
  { name: 'Emergent', color: '#2d607c', textColor: '#ffffff', description: "AI initiatives are becoming more structured, with growing recognition of their importance." },
  { name: 'Structured', color: '#73a3c0', textColor: '#000000', description: "AI is well-integrated into organizational processes with clear strategies and methodologies." },
  { name: 'Integrated', color: '#cef9ad', textColor: '#000000', description: "AI is fully embedded in the organization's operations and culture, driving innovation and value." },
];

function AIReadinessStages({ currentStage }) {
  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: '#f5f5f5' }}>
      <Typography variant="h5" gutterBottom>Maturity Stages</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, height: '300px', position: 'relative' }}>
        {stages.map((stage, index) => (
          <Box
            key={stage.name}
            sx={{
              width: '18%',
              height: `${(index + 1) * 20}%`,
              backgroundColor: stage.color,
              opacity: currentStage >= index ? 1 : 0.7,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              position: 'absolute',
              bottom: 0,
              left: `${index * 20}%`,
              transition: 'all 0.3s ease',
              '&:hover': {
                opacity: 1,
                transform: 'scale(1.05)',
                zIndex: 1,
              },
            }}
          >
            <Tooltip title={stage.description} arrow placement="top">
              <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography
                  variant="caption"
                  sx={{
                    color: stage.textColor,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {stage.name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: stage.textColor,
                    textAlign: 'center',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {stage.description}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

export default AIReadinessStages;
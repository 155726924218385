// src/components/features/AIQuest/AIQuestInsights.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, CircularProgress, Divider } from '@mui/material';
import { useAppContext } from '../../../contexts/AppContext';
import { getAIQuestInsights } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import InsightCard from './InsightCard';
import RecommendationCard from './RecommendationCard';
import RoadmapTimeline from './RoadmapTimeline';
import OrgUserSelector from './OrgUserSelector';

function AIQuestInsights() {
  const { selectedOrganization, selectedUser } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [insights, setInsights] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOrganization && selectedUser) {
      fetchInsights(selectedOrganization, selectedUser);
    }
  }, [selectedOrganization, selectedUser]);

  const fetchInsights = async (org, user) => {
    setLoading(true);
    setError(null);
    try {
      const result = await getAIQuestInsights(org, user);
      setInsights(result.insights);
      setTimestamp(result.timestamp);
    } catch (error) {
      console.error('Error fetching AIQuest insights:', error);
      setError('Failed to fetch insights. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>AI Readiness Insights</Typography>
      <OrgUserSelector />

      {error && <Typography color="error">{error}</Typography>}

      {!insights ? (
        <Box sx={{ p: 4 }}>
          <Typography variant="h5">Select an organization and user to view insights.</Typography>
        </Box>
      ) : (
        <>
          {timestamp && (
            <Typography variant="subtitle1" gutterBottom>
              Last updated: {new Date(timestamp).toLocaleString()}
            </Typography>
          )}
          
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h5" gutterBottom>Overall AI Readiness Score</Typography>
            <Typography variant="h3">{insights.scores.overall_score.toFixed(2)}</Typography>
          </Paper>

          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Category Scores</Typography>
          <Grid container spacing={3}>
            {Object.entries(insights.scores.categories).map(([category, data]) => (
              <Grid item xs={12} sm={6} md={4} key={category}>
                <InsightCard
                  title={category}
                  value={data.score}
                  maxValue={10}
                  description={data.explanation}
                />
              </Grid>
            ))}
          </Grid>

          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Recommendations</Typography>
          <Grid container spacing={3}>
            {insights.recommendations.recommendations.map((rec, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <RecommendationCard
                  category={rec.category}
                  recommendation={rec.recommendation}
                  priority={rec.priority}
                />
              </Grid>
            ))}
          </Grid>

          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>AI Adoption Roadmap</Typography>
          <RoadmapTimeline 
            shortTerm={insights.recommendations.roadmap.short_term} 
            longTerm={insights.recommendations.roadmap.long_term} 
          />
        </>
      )}
    </Box>
  );
}

export default AIQuestInsights;
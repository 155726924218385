// src/components/features/AIQuest/RoadmapTimeline.js
import React from 'react';
import { Box, Paper, Typography, Stepper, Step, StepLabel, StepContent } from '@mui/material';

function RoadmapTimeline({ shortTerm, longTerm }) {
  const steps = [
    {
      label: 'Short-term Goals',
      goals: shortTerm,
    },
    {
      label: 'Long-term Goals',
      goals: longTerm,
    },
  ];

  return (
    <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} active={true}>
            <StepLabel>
              <Typography variant="h6">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <ul>
                  {step.goals.map((goal, goalIndex) => (
                    <li key={goalIndex}>
                      <Typography variant="body2">{goal}</Typography>
                    </li>
                  ))}
                </ul>
              </Paper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default RoadmapTimeline;
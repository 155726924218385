// src/components/features/AIQuest/AIQuestInstructions.js
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function AIQuestInstructions() {
  return (
    <Box sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant="h4" gutterBottom>AIQuest Instructions</Typography>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="body1" paragraph>
          Welcome to the AIQuest Survey. This survey is designed to assess your organization's AI maturity level.
        </Typography>
        <Typography variant="body1" paragraph>
          1. Read each question carefully and select the most appropriate answer.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Be honest in your responses to get the most accurate assessment.
        </Typography>
        <Typography variant="body1" paragraph>
          3. If you're unsure about a question, choose the answer that best represents your organization's current state.
        </Typography>
        <Typography variant="body1">
          4. After completing the survey, you'll receive a detailed report on your AI maturity level and insights for improvement.
        </Typography>
      </Paper>
    </Box>
  );
}

export default AIQuestInstructions;
// src/components/features/DataAnalyst/FileUploadSection.js
import React from 'react';
import { Button } from '@mui/material';
import { useAppContext } from '../../../contexts/AppContext';

const FileUploadSection = ({ onNext }) => {
  const { file, setFile } = useAppContext();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        accept=".csv,.xlsx,.xls"
      />
      {file && <p>Selected file: {file.name}</p>}
      <Button onClick={onNext} disabled={!file}>
        Next
      </Button>
    </div>
  );
};

export default FileUploadSection;
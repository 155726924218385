// src/components/features/AIQuest/IdeaGeneration/IdeaGenerationDashboard.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box, Typography, Button, Stepper, Step, StepLabel, CircularProgress, Tabs, Tab
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PainPointsForm from './PainPointsForm';
import MCQDialog from './MCQDialog';
import SectionRenderer from './SectionRenderer';
import IdeaGenerationHistory from './IdeaGenerationHistory';
import BusinessContext from './BusinessContext';
import api from '../../../../services/api';
import ExportToPDF from './ExportToPDF';
import { useAuth } from '../../../../contexts/AuthContext';
import { useAppContext } from '../../../../contexts/AppContext';

// Import icons
import DescriptionIcon from '@mui/icons-material/Description';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TimelineIcon from '@mui/icons-material/Timeline';
import OrgUserSelector from '../OrgUserSelector';
import AIQuestPlannerHome from './AIQuestPlannerHome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const mainSteps = [
    { title: 'Describe Pain Points', icon: <DescriptionIcon /> },
    { title: 'Provide Context', icon: <QuestionAnswerIcon /> },
    { title: 'Generate Ideas', icon: <LightbulbIcon /> }
  ];
  
  
  const subSections = [
    { key: 'pain_point_identification', title: 'Pain Points', icon: <PriorityHighIcon />, color: '#FF6B6B' },
    { key: 'root_cause_analysis', title: 'Root Cause Analysis', icon: <AssignmentIcon />, color: '#FFA06B' },
    { key: 'idea_generation', title: 'Generated Ideas', icon: <LightbulbIcon />, color: '#FFD56B' },
    { key: 'solution_mapping', title: 'Solution Mapping', icon: <TrendingUpIcon />, color: '#B2FF6B' },
    { key: 'feasibility_assessment', title: 'Feasibility Assessment', icon: <CheckCircleOutlineIcon />, color: '#6BFF8A' },
    { key: 'impact_scoring', title: 'Impact Scoring', icon: <TrendingUpIcon />, color: '#C8326B' },
    { key: 'prioritization_matrix', title: 'Prioritization Matrix', icon: <PriorityHighIcon />, color: '#D3437B' },
    { key: 'action_planning', title: 'Action Planning', icon: <TimelineIcon />, color: '#FF6B6B' },
  ];
  
  
  function IdeaGenerationDashboard() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [painPoints, setPainPoints] = useState('');
    const [mcqQuestions, setMcqQuestions] = useState([]);
    const [mcqAnswers, setMcqAnswers] = useState({});
    const [generatedIdeas, setGeneratedIdeas] = useState({});
    const [businessContext, setBusinessContext] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openMCQDialog, setOpenMCQDialog] = useState(false);
    const [ideaGenerationHistory, setIdeaGenerationHistory] = useState([]);
    const [processId, setProcessId] = useState(null);
    const [uniquePainPoints, setUniquePainPoints] = useState([]);
    const { user } = useAuth();
    const [aiquestInsightsData, setAiquestInsightsData] = useState(null);
    const { selectedOrganization, selectedUser } = useAppContext();
    const [showPainPoints, setShowPainPoints] = useState(false);


    useEffect(() => {
        const cachedData = localStorage.getItem('ideaGenerationData');
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          setGeneratedIdeas(parsedData.generatedIdeas);
          setPainPoints(parsedData.painPoints);
          setMcqAnswers(parsedData.mcqAnswers);
          setBusinessContext(parsedData.businessContext);
          setActiveStep(parsedData.activeStep);
        }
        fetchIdeaGenerationHistory();
      }, []);

    useEffect(() => {
        if (selectedOrganization && selectedUser) {
          fetchIdeaGenerationHistory();
        }
      }, [selectedOrganization, selectedUser]);


  
    useEffect(() => {
        if (selectedOrganization && selectedUser) {
          fetchAIQuestInsights();
        }
      }, [selectedOrganization, selectedUser]);
            
        
      const fetchAIQuestInsights = async () => {
        if (!selectedOrganization || !selectedUser) return;
        
        try {
          setLoading(true);
          console.log("Fetching AIQuest insights...");
          const response = await api.get('/api/aiquest/insights', {
            params: {
              organization: selectedOrganization,
              interviewee: selectedUser
            }
          });
          console.log("Received AIQuest insights:", response.data);
          setAiquestInsightsData(response.data);
        } catch (error) {
          console.error("Error fetching AIQuest insights:", error);
          setAiquestInsightsData(null);
        } finally {
          setLoading(false);
        }
      };

      const aiquestInsights = useMemo(() => {
        return aiquestInsightsData?.insights;
      }, [aiquestInsightsData]);

      
      const fetchIdeaGenerationHistory = async () => {
        try {
          const response = await api.get('/api/aiquest/idea-generation-history', {
            params: {
              organization_name: selectedOrganization,
              interviewee_name: selectedUser
            }
          });
          setIdeaGenerationHistory(response.data);
        } catch (error) {
          console.error('Error fetching idea generation history:', error);
          setError('Failed to fetch idea generation history. Please try again.');
        }
      };
      

    const fetchUniqueIdeaGenerationHistory = async () => {
        setLoading(true);
        try {
          const response = await api.get('/api/aiquest/unique-idea-generation-history');
          setIdeaGenerationHistory(response.data);
        } catch (error) {
          console.error('Error fetching unique idea generation history:', error);
          setError('Failed to fetch idea generation history. Please try again.');
        } finally {
          setLoading(false);
        }
      };
    
    //   useEffect(() => {
    //     fetchUniqueIdeaGenerationHistory();
    //   }, []);


      const fetchUniquePainPoints = async () => {
        setLoading(true);
        try {
          const response = await api.get('/api/aiquest/unique-pain-points');
          setUniquePainPoints(response.data);
        } catch (error) {
          console.error('Error fetching unique pain points:', error);
          setError('Failed to fetch pain points history. Please try again.');
        } finally {
          setLoading(false);
        }
      };   

      const handleSelectProcess = async (processId) => {
        setLoading(true);
        try {
          const response = await api.get(`/api/aiquest/complete-process/${processId}`);
          const process = response.data;
          setGeneratedIdeas(process.generated_ideas);
          setPainPoints(process.pain_points);
          setMcqAnswers(process.mcq_answers.reduce((acc, answer) => {
            acc[answer.question_text] = answer.answer_text;
            return acc;
          }, {}));
          setBusinessContext(process.generated_ideas.business_context);
          setActiveStep(2);
          saveToCache();
        } catch (error) {
          console.error('Error fetching complete process:', error);
          setError('Failed to fetch process details. Please try again.');
        } finally {
          setLoading(false);
        }
      };

    const handleDeleteProcess = async (processId) => {
        try {
          await api.delete(`/api/aiquest/idea-generation-process/${processId}`);
          // Refresh the history after deletion
          fetchIdeaGenerationHistory();
        } catch (error) {
          console.error('Error deleting process:', error);
          setError('Failed to delete process. Please try again.');
        }
      };
      
    const saveToCache = () => {
      const dataToCache = {
        generatedIdeas,
        painPoints,
        mcqAnswers,
        businessContext,
        activeStep
      };
      localStorage.setItem('ideaGenerationData', JSON.stringify(dataToCache));
    };
  
    const formatMcqAnswers = () => {
        return mcqQuestions.map(question => ({
          question_text: question.text,
          answer_text: mcqAnswers[question.id] 
            ? question.options.find(opt => opt.id === mcqAnswers[question.id])?.text 
            : ''
        }));
      };

      const handleStepClick = (step) => {
        if (step < activeStep) {
          setActiveStep(step);
        }
      };

      const handleStartIdeaGeneration = () => {
        setShowPainPoints(true);
      };
    

      const handleBackToPlannerHome = () => {
        setShowPainPoints(false);
      };
    
    
    //   if (!showPainPoints) {
    //     return <AIQuestPlannerHome onStartPainPoints={handleStartPainPoints} onBack={handleBackToHome}/>;
    //   }

      const handlePainPointsSubmit = async () => {
        setLoading(true);
        try {
          const response = await api.post('/api/aiquest/generate-mcq-questions', {
            pain_points_description: painPoints,
            organization_name: selectedOrganization,
            interviewee_name: selectedUser
          });
          console.log('Received MCQ questions:', response.data.mcq_questions);
          setMcqQuestions(response.data.mcq_questions);
          setProcessId(response.data.process_id);
          setOpenMCQDialog(true);
          setActiveStep(1); // Move to the "Provide Context" step
        } catch (error) {
          console.error('Error generating MCQ questions:', error);
          setError('Failed to generate questions. Please try again.');
        } finally {
          setLoading(false);
        }
      };

      const handleMcqSubmit = async () => {
        setLoading(true);
        setOpenMCQDialog(false);
        try {
            const formattedAnswers = formatMcqAnswers();
            console.log('Sending MCQ answers:', formattedAnswers);
            console.log('Current user:', user);
        
            const requestData = {
              process_id: processId,
              mcq_answers: formattedAnswers,
              username: user?.username,
              organization_name: selectedOrganization,
              interviewee_name: selectedUser        
            };
            console.log('Request data:', requestData);
        
            const mcqResponse = await api.post('/api/aiquest/process-mcq-answers', requestData);
            console.log('Received MCQ response:', mcqResponse.data);
            setBusinessContext(mcqResponse.data);
      
      
          // Call pain points API
          const painPointsResponse = await api.post('/api/aiquest/pain-point-identification', {
            process_id: processId,
            pain_points_description: painPoints,
            business_context: mcqResponse.data.business_context,
            organization_name: selectedOrganization,
            interviewee_name: selectedUser
      
          });
          console.log('Received pain points response:', painPointsResponse.data);
          setGeneratedIdeas(prev => ({ ...prev, pain_point_identification: painPointsResponse.data }));
      
          setActiveStep(2);  // Move to the Generate Ideas step
          setActiveTab(0);  // Set the first tab (Pain Points) as active
        } catch (error) {
          console.error('Error processing MCQ answers:', error);
          setError('Failed to process answers. Please try again.');
        } finally {
          setLoading(false);
        }
      };
      
      const handleNext = async () => {
        setLoading(true);
        setError(null);
        try {
          const currentSection = subSections[activeTab];
          const nextSectionIndex = activeTab + 1;
          
          if (nextSectionIndex < subSections.length) {
            const nextSection = subSections[nextSectionIndex];
            const endpoint = `/api/aiquest/${nextSection.key.replace(/_/g, '-')}`;
            
            // Ensure all required data is present
            const requestData = {
                process_id: processId,
                ...generatedIdeas,
                business_context: businessContext,
                mcq_answers: formatMcqAnswers()
            };

            // Check if all required fields are present and valid
            if (!requestData.process_id || !requestData.business_context || !requestData.mcq_answers) {
                throw new Error('Missing required data for API request');
            }

            const response = await api.post(endpoint, requestData);        

            console.log(`Response for ${nextSection.key}:`, response.data);
            setGeneratedIdeas(prev => {
                const updated = { ...prev, [nextSection.key]: response.data };
                // Update the backend with the new generated ideas
                api.post(`/api/aiquest/update-process/${processId}`, { generated_ideas: updated });
                return updated;
        
            });
            setActiveTab(nextSectionIndex);
            
          }
        } catch (error) {
          console.error('Error:', error);
          setError('Failed to process. Please try again.');
        } finally {
          setLoading(false);
        }
      };   
    

      const handleRefresh = async () => {
        setLoading(true);
        setError(null);
        try {
          const currentSection = subSections[activeTab];
          const endpoint = `/api/aiquest/${currentSection.key.replace(/_/g, '-')}`;
          
          const response = await api.post(endpoint, { 
            process_id: processId,
            ...generatedIdeas,
            business_context: businessContext,
            mcq_answers: formatMcqAnswers()
          });
          
          setGeneratedIdeas(prev => ({ ...prev, [currentSection.key]: response.data }));
        } catch (error) {
          console.error('Error refreshing data:', error);
          setError('Failed to refresh. Please try again.');
        } finally {
          setLoading(false);
        }
      };

      const renderStepContent = () => {
        switch (activeStep) {
          case 0:
            return (
              <>
                <PainPointsForm
                  painPoints={painPoints}
                  setPainPoints={setPainPoints}
                  onSubmit={handlePainPointsSubmit}
                  loading={loading}
                />
            <IdeaGenerationHistory 
            history={ideaGenerationHistory}
            loading={loading}
            onSelectProcess={(process) => {
                setGeneratedIdeas(process.generated_ideas);
                setPainPoints(process.pain_points);
                setMcqAnswers(process.mcq_answers.reduce((acc, answer) => {
                acc[answer.question_text] = answer.answer_text;
                return acc;
                }, {}));
                setBusinessContext(process.generated_ideas.business_context);
                setActiveStep(2);
                saveToCache();
            }}
            onDeleteProcess={handleDeleteProcess}
            />
              </>
            );
          case 1:
            return (
                <>
                  <MCQDialog
                    open={openMCQDialog}
                    onClose={() => setOpenMCQDialog(false)}
                    questions={mcqQuestions}
                    answers={mcqAnswers}
                    setAnswers={setMcqAnswers}
                    onSubmit={handleMcqSubmit}
                    loading={loading}
                  />
                  {businessContext && (
                    <BusinessContext
                      businessContext={businessContext}
                      painPoints={painPoints}
                      mcqAnswers={mcqAnswers}
                      mcqQuestions={mcqQuestions}
                      aiquestInsights={aiquestInsights}
                      organization={selectedOrganization}
                      interviewee={selectedUser}
                  
                    />
                  )}
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => setActiveStep(0)} variant="outlined">
                      Back to Pain Points
                    </Button>
                    <Button onClick={() => setActiveStep(2)} variant="contained" disabled={!businessContext}>
                      Next: Generate Ideas
                    </Button>
                  </Box>
                </>
              );
          case 2:
            return (
                <>
                  <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ mb: 3 }}
                  >
                    {subSections.map((section, index) => (
                      <Tab key={index} label={section.title} icon={section.icon} />
                    ))}
                  </Tabs>
                  <SectionRenderer
                    sectionKey={subSections[activeTab].key}
                    title={subSections[activeTab].title}
                    color={subSections[activeTab].color}
                    data={generatedIdeas[subSections[activeTab].key]}
                  />
                  {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => setActiveStep(1)} variant="outlined">
                      Back to Provide Context
                    </Button>
                    <Button onClick={handleNext} variant="contained" disabled={loading}>
                      Next
                    </Button>
                  </Box> */}
                  <Button onClick={handleRefresh} disabled={loading}>
                    Refresh
                  </Button>
                </>
              );
          default:
            return null;
        }
      };
      
      console.log("AIQuest Insights before passing to BusinessContext:", aiquestInsights);

const renderContent = () => {
if (!showPainPoints) {
    return <AIQuestPlannerHome onStartIdeaGeneration={handleStartIdeaGeneration} />;
}
    
  return (
    <Box sx={{ p: 4, maxWidth: 1200, margin: '0 auto' }}>
       <Button 
          startIcon={<ArrowBackIcon />} 
          onClick={handleBackToPlannerHome}
          sx={{ mb: 2 }}
        >
          Back to AIQuest Planner
        </Button>
      <Typography variant="h4" gutterBottom color="primary">
        AIQuest Planner
      </Typography>
      <OrgUserSelector />
            <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {mainSteps.map((step, index) => (
            <Step key={step.title}>
            <StepLabel 
                StepIconComponent={() => step.icon}
                onClick={() => {
                if (index <= activeStep) {
                    setActiveStep(index);
                }
                }}
                style={{ cursor: index <= activeStep ? 'pointer' : 'not-allowed' }}
            >
                {step.title}
            </StepLabel>
            </Step>
        ))}
        </Stepper>



      {renderStepContent()}

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {loading && <CircularProgress />}
      </Box>

      {activeStep === 2 && activeTab < subSections.length - 1 && !loading && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleNext} variant="contained" color="primary">
            Next
          </Button>
        </Box>
      )}

      {activeStep === 2 && activeTab === subSections.length - 1 && !loading && (
        <Typography sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
          All steps completed - you're finished
        </Typography>
      )}

      {activeStep > 0 && !loading && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setActiveStep(0)}
          >
            Start Over
          </Button>
          <ExportToPDF 
  generatedIdeas={generatedIdeas}
  businessContext={businessContext}
  painPoints={painPoints}
  mcqAnswers={mcqAnswers}
  mcqQuestions={mcqQuestions}
/>
        </Box>
      )}

    {/* <IdeaGenerationHistory 
    history={ideaGenerationHistory}
    onSelectProcess={(process) => {
        setGeneratedIdeas(process.generated_ideas);
        setPainPoints(process.pain_points);
        setMcqAnswers(process.mcq_answers.reduce((acc, answer) => {
        acc[answer.question_text] = answer.answer_text;
        return acc;
        }, {}));
        setBusinessContext(process.generated_ideas.business_context);
        setActiveStep(2);
        saveToCache();
    }}
    onDeleteProcess={handleDeleteProcess}
    /> */}
    </Box>
  );
};

  return renderContent();
}

export default IdeaGenerationDashboard;
// src/components/features/SmartSearch/DatabaseSelection.js
import React from 'react';
import { Typography, Grid, Paper, Button, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const databases = [
  { 
    title: 'Product', 
    description: 'AMA about product feature and releases',
    details: 'The Product database contains comprehensive information about our product features, release schedules, and development roadmap.'
  },
  { 
    title: 'Financials', 
    description: 'AMA about booked and pipeline revenues across clients',
    details: 'The Financials database houses all financial data related to our company operations.'
  },
  { 
    title: 'Marketing', 
    description: 'AMA about marketing and use cases to reference',
    details: 'The Marketing database is a rich repository of all marketing-related information.'
  },
  { 
    title: 'Research', 
    description: 'AMA about new initiatives',
    details: 'The Research database encompasses all ongoing and completed research projects and new initiatives.'
  },
];

function DatabaseSelection({ onDatabaseSelect }) {
  return (
    <>
      <Typography variant="h4" sx={{ mb: 2 }}>Select Database</Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }}>Select what's on your mind</Typography>
      <Grid container spacing={3}>
        {databases.map((database) => (
          <Grid item xs={12} sm={6} md={3} key={database.title}>
            <Paper 
              sx={{ 
                p: 3, 
                height: '100%', 
                bgcolor: '#FFF1F1', 
                display: 'flex', 
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() => onDatabaseSelect(database)}
            >
              <Typography variant="h6" sx={{ mb: 2, color: '#4A6572' }}>
                {database.title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, color: '#4A6572', flexGrow: 1 }}>
                {database.description}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button 
                  color="primary" 
                  sx={{ textTransform: 'none' }}
                >
                  Select
                </Button>
                <Tooltip title={database.details} placement="top">
                  <InfoIcon sx={{ color: '#4A6572', cursor: 'default' }} />
                </Tooltip>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default DatabaseSelection;
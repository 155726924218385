// src/components/layout/Sidebar.js
import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LandscapeIcon from '@mui/icons-material/Landscape';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';

function Sidebar() {
  const navigate = useNavigate();
  const { 
    chatSessions, 
    currentChatIndex, 
    onNewChat, 
    onSelectChat, 
    onDeleteChat,
    setSelectedDatabase 
  } = useAppContext();

  const getSubject = (messages) => {
    const firstMessage = messages.find(msg => msg.sender === 'user');
    if (firstMessage) {
      const subject = firstMessage.text.slice(0, 30);
      return subject.length < firstMessage.text.length ? `${subject}...` : subject;
    }
    return 'New Chat';
  };

  const handleNewChat = () => {
    onNewChat();
    setSelectedDatabase(null);
    navigate('/smart-search');
  };

  const handleHomeClick = () => {
    setSelectedDatabase(null);
    navigate('/');
  };

  const handleSmartSearchClick = () => {
    setSelectedDatabase(null);
    navigate('/smart-search');
  };

  const handleChatClick = (index) => {
    onSelectChat(index);
    const chat = chatSessions[index];
    if (chat.feature === 'smart-search') {
      navigate('/smart-search', { state: { loadChat: true } });
    } else if (chat.feature === 'data-analyst') {
      navigate('/data-analyst', { state: { loadChat: true } });
    }
  };

  const handleAIQuestLandingClick = () => {
    navigate('/aiquest/landing');
  };
  
  return (
    <Box sx={{ height: '100%', borderRight: '1px solid #e0e0e0', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleHomeClick}>
          <Box component="span" sx={{ color: '#C8326B', fontWeight: 'bold' }}>WISSP.AI</Box>
        </Typography>
      </Box>
      <List>
        <ListItem 
          button 
          onClick={handleNewChat}
          sx={{ 
            py: 1,
            '&:hover': { bgcolor: 'rgba(200, 50, 107, 0.05)' },
          }}
        >
          <AddIcon sx={{ mr: 1 }} />
          <ListItemText primary="New Chat" />
        </ListItem>
        <ListItem 
          button 
          onClick={handleHomeClick}
          sx={{ 
            py: 1,
            '&:hover': { bgcolor: 'rgba(200, 50, 107, 0.05)' },
          }}
        >
          <HomeIcon sx={{ mr: 1 }} />
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem 
          button 
          onClick={handleSmartSearchClick}
          sx={{ 
            py: 1,
            '&:hover': { bgcolor: 'rgba(200, 50, 107, 0.05)' },
          }}
        >
          <SearchIcon sx={{ mr: 1 }} />
          <ListItemText primary="Smart Search" />
        </ListItem>
        <ListItem 
  button 
  onClick={handleAIQuestLandingClick}
  sx={{ 
    py: 1,
    '&:hover': { bgcolor: 'rgba(200, 50, 107, 0.05)' },
  }}
>
  <LandscapeIcon sx={{ mr: 1 }} />
  <ListItemText primary="AIQuest Landing" />
</ListItem>
      </List>
      <Typography variant="subtitle2" sx={{ mt: 2, mb: 1, color: 'text.secondary' }}>
        Chat History
      </Typography>
      <List sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
        {chatSessions.map((chat, index) => (
          <ListItem 
            key={index} 
            disablePadding 
            sx={{ 
              bgcolor: currentChatIndex === index ? 'rgba(200, 50, 107, 0.1)' : 'transparent',
              '&:hover': { bgcolor: 'rgba(200, 50, 107, 0.05)' },
              py: 0.5,
            }}
          >
            <Tooltip title={getSubject(chat.messages)} placement="right">
              <ListItemText 
                primary={getSubject(chat.messages)}
                onClick={() => handleChatClick(index)}
                sx={{ 
                  cursor: 'pointer',
                  '& .MuiListItemText-primary': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '0.9rem',
                  }
                }}
              />
            </Tooltip>
            <IconButton onClick={(e) => { e.stopPropagation(); onDeleteChat(index); }} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>    
    </Box>
  );
}

export default Sidebar;
// src/components/features/SmartSearch/ChatWindow.js

import React from 'react';
import { Box, Typography, Chip, CircularProgress, Link } from '@mui/material';
import { useAppContext } from '../../../contexts/AppContext';

function ChatWindow() {
  const { chatSessions, currentChatIndex, isLoading } = useAppContext();

  const renderSource = (source) => {
    if (source.startsWith('http://') || source.startsWith('https://')) {
      return (
        <Link href={source} target="_blank" rel="noopener noreferrer">
          {source}
        </Link>
      );
    }
    return source;
  };

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
      {chatSessions[currentChatIndex]?.messages.map((message, index) => (
        <Box key={index} sx={{ mb: 2, textAlign: message.sender === 'user' ? 'right' : 'left' }}>
          <Typography 
            variant="body1" 
            sx={{ 
              display: 'inline-block',
              p: 1,
              borderRadius: 1,
              bgcolor: message.sender === 'user' ? '#FFF1F1' : message.sender === 'system' ? '#FFD700' : '#F0F0F0',
              color: 'black',
              maxWidth: '70%',
            }}
          >
            {message.text}
          </Typography>
          {message.sources && message.sources.length > 0 && (
            <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Typography variant="caption" sx={{ mr: 1 }}>Sources:</Typography>
              {message.sources.map((source, sourceIndex) => (
                <Chip
                  key={sourceIndex}
                  label={renderSource(source.file)}
                  size="small"
                  sx={{ fontSize: '0.7rem' }}
                />
              ))}
            </Box>
          )}
        </Box>
      ))}
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default ChatWindow;